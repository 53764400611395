import './index.css'
import { useNavigate } from 'react-router-dom'

export default function Footer() {

    var isAuthenticated = window.localStorage.getItem('isAuthenticated')

    var navigate = useNavigate()

    return (
        <footer className="footer mt-auto">
        <div className="main-footer container-fluid pb-5 mx-auto">

            <div className="row px-2 px-md-4">
                <div className="col-md order-2 order-md-1">
                    <div className="row">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mx-auto mx-md-0">
                            <img src="/logos/logo-secondary.svg" alt="Fittir logo"
                                className="footer-logo img-fluid"/>
                        </div>

                    </div>
                    <div className="row justify-content-center justify-content-md-start">
                        <div className="col-12 col-md-9 col-lg-10 text-center text-md-start">
                            <p className="footer-text my-0 mb-5">We are on a mission to rid the world of bad running shoe decisions, helping people enjoy the benefits of running irrespective of experience or location.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md order-1 order-md-2 mx-auto mx-md-2">
                    <div className="row justify-content-center">
                        <div className="col-3 col-lg-3">
                            <div className="row mt-5">
                                <h4 className="footer-heading pb-2">Site</h4>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link" href="/">Home</a></p>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link" href="/shoe-finder/intro">Shoe finder</a></p>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link" href={isAuthenticated ? "/dashboard" : "/auth/login"}>{isAuthenticated ? "Dashboard" : "Login"}</a></p>
                            </div>
                        </div>
                        <div className="col-3 col-lg-3">
                            <div className="row mt-5">
                                <h4 className="footer-heading pb-2">Network</h4>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link" href=""><i>...coming soon</i></a></p>
                            </div>
                        </div>
                        <div className="col-3 col-lg-3">
                            <div className="row mt-5">
                                <h4 className="footer-heading pb-2">Company</h4>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link" href="/about">About</a></p>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link" href="https://fittir.changelogfy.com/changelog/en">Product updates</a></p>
                            </div>
                        </div>
                        <div className="col-3 col-lg-3 m">
                            <div className="row mt-5">
                                <h4 className="footer-heading pb-2">Support</h4>
                            </div>
                            <div className="row">
                                <p className="footer-text"><a className="footer-link"
                                        href="mailto:hi@fittir.io">hi@fittir.io</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <div className="row justify-content-between align-items-center privacy-terms-box mx-auto px-4">
                    
                    <div className="col-10 col-md-5 mx-auto mx-md-0 text-center mb-3 mb-md-0">
                        <div className="row justify-content-center justify-content-lg-start align-items-center">
                            <div className="col-auto">
                                <div className="row">
                                        <div className="col-auto mb-3 mb-md-0">
                                            <a href="https://www.instagram.com/fittir.io/" target='_blank'><img src="/icons/social/instagram_white.svg" alt="" /></a>
                                        </div>
                                        <div className="col-auto mb-3 mb-md-0">
                                            <a href="https://www.linkedin.com/company/fittir" target='_blank'><img src="/icons/social/linkedIn_white.svg" alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-11 col-md-auto">
                                    <p className='my-0 footer-text my-0'>Built with 💙 in New Zealand by <span><a href="https://www.instagram.com/run.ant.run">@run.ant.run</a></span></p>   
                                </div>
                        </div>
                    </div>
                
                    <div className="col-10 col-md-5 mx-auto mx-md-0 text-center">
                        <div className="row justify-content-around justify-content-lg-end">
                            <div className="col-auto text-center">
                                <p className="footer-text my-0">Fittir Limited 2023. All right reserved.</p>
                            </div>
                            <div className="col-auto text-center">
                                <p className="footer-text my-0"><a className="footer-link" href="/privacy-policy">(Privacy policy)</a></p>
                            </div>
                        </div>
                    </div>
                    
                </div>

        </div> 
        
    </footer>
    )
}