import { useState } from "react"
import axios from "axios";
import * as Sentry from "@sentry/react";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TextField from "@mui/material/TextField";
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton"
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import ResponseSection from "./ResponseSection/index.jsx";
import Alert from '@mui/material/Alert';


export default function AskAIPanel({ questionContext = null, title = "Got a question?", description = null, shoe = null}) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl, setProcessing, isProcessing, trackEvent } = useAppContext()

    var [context, setContext] = useState()
    var [response, setResponse] = useState()
    var [question, setQuestion] = useState()
    var [questionError, setQuestionError] = useState(false)

    var context = `You are a helpful assistant, helping runners using our software platform to answer questions about a running shoe they are viewing based on the following list of positives and negatives (sent after this message). These pros and cons have been summarised across a number of different online website reviews. That running shoe is the ${`${shoe?.shoe.brand.name} ${shoe?.shoe.model} ${shoe?.shoe.version}, ${shoe?.shoe.surface} running shoe`}. Please return your answer in a plain text format. If there is no answer to the question in the reference data provided, please respond honestly and let the user know that nothing could be found in the reviews.`

    const handleSubmit = (evt) => {
        if (!question) {
            setQuestionError(true)
        } else {
            setProcessing(true)
            var url = `${requestUrl}/api/integrations/open_ai/question`
            axios({
                url: url,
                method: "POST",
                data: {
                    shoe: shoe,
                    context: context || null,
                    question: question
                },
                withCredentials: true
            })
            .then((res) => {
                setResponse(res.data.questionResponse ? res.data.questionResponse : 'Whoops, something went wrong!')
                trackEvent('ChatGPT shoe question asked', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`, question: question, response: res.data.questionResponse})
                setProcessing(false)
            })
            .catch((err) => {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: errorMessage
                    }
                })
            })
        }

    }
if (shoe?.shoe.websiteReviews.length > 0) {
    return (
        <div className="col ask-ai-tile py-3 px-3">
            <div className="row align-items-center mt-2">
                <div className="col-auto text-start">
                    <SmartToyIcon />
                </div>
                <div className="col px-0 text-start">
                    <h3 className="my-0">{title}</h3>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-start">
                    <p>Ask Fittir a question about this shoe, and get a response based on the consensus across our database of professional in-depth, and amateur reviews.</p>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                <TextField 
                    error={questionError} 
                    disabled={isProcessing}
                    helperText={questionError ? "We'll need something to ask!": "e.g how does the cushion on this shoe feel?"} 
                    id="AIQuestionField" 
                    label="Ask a question" 
                    variant="outlined" 
                    name="question" 
                    multiline  
                    fullWidth 
                    value={question} 
                    onChange={(evt) => question = evt.target.value} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    disabled={isProcessing}
                                    onClick={handleSubmit}
                                >
                                    <SendIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ResponseSection isProcessing={isProcessing} response={response} />
                </div>
            </div>
        </div>
    )
} else {
    return (
        <div className="col ask-ai-tile py-3 px-3">
            <div className="row align-items-center mt-2">
                <div className="col-auto">
                    <SmartToyIcon />
                </div>
                <div className="col px-0 text-start">
                    <h3 className="my-0">{title}</h3>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col text-start">
                    <p>Ask Fittir a question, and get a response based on the consensus across every online review for this shoe.</p>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                <TextField 
                    error={questionError} 
                    disabled
                    helperText={questionError ? "We'll need something to ask!": "e.g how does the cushion on this shoe feel?"} 
                    id="AIQuestionField" 
                    label="Ask a question" 
                    variant="outlined" 
                    name="question" 
                    multiline  
                    fullWidth 
                    value={question} 
                    onChange={(evt) => question = evt.target.value} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    disabled
                                    onClick={handleSubmit}
                                >
                                    <SendIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <Alert severity="warning">Sorry, we need a few more reviews before we can give you an accurate answer.</Alert>
                </div>
            </div>
        </div>
    )
}}