import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonBase, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ChipComponent from '../../../Chip';
import Divider from '@mui/material/Divider';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppContext } from '../../../../utils/ContextProvider.jsx'
import CheckIcon from '@mui/icons-material/Check';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Tooltip from '@mui/material/Tooltip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as Sentry from "@sentry/react";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export default function RecommendationCard({ recommendation = null }) {

    var [user, userShoes] = useOutletContext()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, trackEvent, requestUrl, isProcessing, setProcessing } = useAppContext()

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate()

    const location = useLocation()

    const [shoes, setShoes] = useState(recommendation.shoes)

    var colorwayIndex = shoes[0]?.shoe.colorways?.indexOf(shoes[0].shoe.colorways.filter((colorway) => colorway?.widthOptions?.some(widthOption => recommendation.requirements.width.traits.includes(widthOption)))[0]) 

    if (colorwayIndex == -1) {
        colorwayIndex = 0
    }

    useEffect(() => {
        if (recommendation.preferences.shoeBrands.length > 0) {
            setShoes(
                shoes.filter((shoe) => {
                    return recommendation.preferences.shoeBrands.includes(shoe.shoe.brand.name) && parseInt(shoe.shoe.msrp) <= recommendation.preferences.shoeBudget.highPrice && shoe.shoe.latestModel === "TRUE" && (userShoes?.length > 0 ? (userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0]?.shoeReviewId.rating) > 3 || !userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0] : (shoe) )
                }).sort((a,b) => {
                    return b.score - a.score ||  parseFloat(b.shoe.averageWebsiteScore).toFixed(1)  - parseFloat(a.shoe.averageWebsiteScore).toFixed(1) || b.shoe.websiteReviews.length - a.shoe.websiteReviews.length
                })
            )
        } else {
            setShoes(
                shoes.filter((shoe) => {
                    return parseInt(shoe.shoe.msrp) <= recommendation.preferences.shoeBudget.highPrice && shoe.shoe.latestModel === "TRUE" && (userShoes?.length > 0 ? (userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0]?.shoeReviewId.rating) > 3 || !userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0] : (shoe))
                }).sort((a,b) => {
                    return b.score - a.score || parseFloat(b.shoe.averageWebsiteScore).toFixed(1)  - parseFloat(a.shoe.averageWebsiteScore).toFixed(1) || b.shoe.websiteReviews.length - a.shoe.websiteReviews.length
                })
            )
        }
    }, [])

    const handleDelete = (evt) => {
        setProcessing(true)
        axios({
            url: `${requestUrl}/api/recommendations/${recommendation._id}`,
            method: "DELETE",
            withCredentials: true
        })
        .then((res) => {
            trackEvent('Recommendation deleted')
            setProcessing(false)
            setOpen(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `Recommendation deleted!`
                }
            })
            window.location.reload()
            
        })
        .catch((err) => {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }

    const handleClick = (evt) => {
        navigate(`/dashboard/recommendations/${recommendation._id}/shoes/${shoes[0].shoe.brand.slug}_${shoes[0].shoe.model}_${shoes[0].shoe.version}`, { state: { shoe: shoes[0], preferences: recommendation.preferences, requirements: recommendation.requirements, shoeColorway: shoes[0].shoe.colorways[colorwayIndex] } })
    }

    return (
        <div style={{height: '100%'}}>
            <Card key={recommendation._id}>
                {shoes[0]? 
                <CardContent className='px-0'>
                    {/* Header (title + action) */}
                    <div className="row px-3 pb-3 align-items-center">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <img className='img img-fluid' src={`/icons/shoeSurfaces/${recommendation.preferences?.shoeSurface}.svg`} alt="" />
                                        </div>
                                        <div className="col-auto px-0">
                                            <img className='img img-fluid' src={`/icons/shoeTypes/${recommendation.preferences?.shoeUsage}.svg`}  alt="" />
                                        </div>
                                        <div className="col text-start">
                                            <h3 className='my-0'>{((recommendation.preferences?.shoeUsage).charAt(0).toUpperCase() + recommendation.preferences?.shoeUsage.slice(1)).replace('_', ' ') },  {recommendation.preferences?.shoeSurface} running {recommendation.preferences.trailSurface ? `(${recommendation.preferences?.trailSurface}${recommendation.preferences?.conditions? `, ${recommendation.preferences.conditions}` : ""})` : (recommendation.preferences.speedType ? `(${recommendation.preferences?.speedType})` : null )} </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <Tooltip title="Delete recommendation">
                                <IconButton 
                                    aria-label="deleteRecommendation"
                                    onClick={handleClickOpen}
                                    >
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <CardActionArea 
                        onClick={handleClick}
                        >

                    <div className="row mt-4 justify-content-center">
                        <div className="col-auto mt-2">
                            <ChipComponent
                                icon={<StarOutlineIcon />}
                                size={'large'}
                                label={shoes[0] && (shoes[0]?.score / shoes[0]?.maxScore) > 0.95 ?  `Recommended` : `Great option`}
                                color={'success'}
                            />
                        </div>
                        { (shoes[0] && (shoes[0]?.score / shoes[0]?.maxScore) < 1.00)  && (recommendation.preferences.shoeBrands.length > 0 || recommendation.preferences.shoeBudget.highPrice !== 550) ?
                        <div className="col-auto mt-2">
                            <ChipComponent
                                icon={<InfoOutlinedIcon />}
                                size={'large'}
                                label={`Better suited options outside brand preferences`}
                                color={'default'}
                            />
                        </div>
                        : null}
                    </div>
                    
                    {/* Brand image */}
                    <div className="row">
                        <div className="col-8 mx-auto text-center">
                            <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoes[0].shoe.brand.slug}.png`} alt="" />
                        </div>
                    </div>


                    {/* Shoe image */}
                    <div className="row pt-2">
                        <div className="col-10 mx-auto text-center">
                            <img loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoes[0].shoe.brand.slug}_${shoes[0].shoe.modelSlug}_${shoes[0].shoe.version}_${shoes[0].shoe.colorways[colorwayIndex]?.colorwayCode}.png`} alt="" />
                        </div>
                    </div>

                    

                    {/* Shoe name */}
                    <div className="row pt-4">
                        <div className="col--auto mx-auto text-center px-5">
                            <h1>{shoes[0].shoe.brand.name} {shoes[0].shoe.model} {shoes[0].shoe.version}</h1>
                        </div>
                    </div>

                    {/* Match pill */}
                    <div className="row py-3 px-4 justify-content-center">
                        <div className="col-auto my-1">
                            <ChipComponent
                                icon={<ThumbUpOffAltIcon />}
                                variant={'outlined'}
                                label={`${((shoes[0].score / shoes[0].maxScore) * 100).toFixed(0)} % match for your requirements`}
                                color={'default'}
                            />
                        </div>
                        <div className="col-auto my-1">
                            <ChipComponent
                                icon={<PersonOutlineIcon />}
                                label={`Highest rated in category (${parseFloat(shoes[0].shoe.averageWebsiteScore).toFixed(2)}/10)`}
                                color={'default'}
                                variant={'outlined'}
                            />
                        </div>
                        <div className="col-auto my-1">
                            <ChipComponent
                                icon={<CheckIcon />}
                                label={`Fits your preferences`}
                                color={'default'}
                                variant={'outlined'}
                            />
                        </div>
                    </div>
                    

                    </CardActionArea>

                <Divider variant="middle" />
                </CardContent>
                : 
                <CardContent>
                    <div className="row px-3 pb-3 align-items-center">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <img className='img img-fluid' src={`/icons/shoeSurfaces/${recommendation.preferences?.shoeSurface}.svg`} alt="" />
                                        </div>
                                        <div className="col-auto px-0">
                                            <img className='img img-fluid' src={`/icons/shoeTypes/${recommendation.preferences?.shoeUsage}.svg`}  alt="" />
                                        </div>
                                        <div className="col text-start">
                                            <h3 className='my-0'>{(recommendation.preferences?.shoeUsage).charAt(0).toUpperCase() + recommendation.preferences?.shoeUsage.slice(1) }, {recommendation.preferences?.shoeSurface} running </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <Tooltip title="Delete recommendation">
                                <IconButton 
                                    aria-label="deleteRecommendation"
                                    onClick={handleClickOpen}
                                    >
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col text-center">
                            <p>We couldn't recommend a shoe that fits with your preferences.</p>
                            <p>Try expanding your preferences by viewing all options below</p>
                        </div>
                    </div>
                    
                </CardContent>
                
                }
            <CardActions>
                <div className="col-auto mx-auto text-center mb-3">
                    <Button 
                        variant='contained'
                        href={`/dashboard/recommendations/${recommendation._id}`}
                        endIcon={<ReadMoreIcon />}
                    >
                    View all options
                    </Button>
                </div>
                
            </CardActions>
        </Card>




        <React.Fragment>
                <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        maxWidth='sm'
                        className='px-4'
                    >
                    <div className="row mx-1 my-2">
                        <div className="col px-3 my-2">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <h3 className="my-0">Are you sure you like to delete this recommendation?</h3>
                                </div>
                                <div className="col text-end d-none d-md-inline">
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <p>Once deleted, you will have to create another recommendation to recover it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogActions>
                        <div className="col-auto">
                            <Button autoFocus onClick={handleClose}>
                                Cancel
                            </Button>
                        </div>
                        
                        <div className="col-auto px-2 my-2">
                            <Button 
                                autoFocus    
                                variant='contained' 
                                color='error'   
                                onClick={handleDelete}
                                disabled={isProcessing == true ? true : false}
                                endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                
                                >
                                Confirm
                            </Button>
                        </div>
                        
                    </DialogActions>
                </BootstrapDialog>
        </React.Fragment>
        </div>
        

        
    )
}