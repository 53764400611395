var runningProfileOptions = {
    age: [
        {
            title: "0-18y/o",
            subHeading: null,
            name: "age",
            value: "0-18y/o"
    
        },
        {
            title: "19-35y/o",
            subHeading: null,
            name: "age",
            value: "19-35y/o"
    
        },
        {
            title: "36-50y/o",
            subHeading: null,
            name: "age",
            value: "36-50y/o"
    
        },
        {
            title: ">50y/o",
            subHeading: null,
            name: "age",
            value: ">50y/o"
    
        }
    ],
    archType: [
        {
            title: "Low",
            subHeading: null,
            name: "archType",
            value: "low",
            imageUrl: "/formAssets/archType/low.png"
        },
        {
            title: "Normal",
            subHeading: null,
            name: "archType",
            value: "normal",
            imageUrl: "/formAssets/archType/normal.png"
        },
        {
            title: "High",
            subHeading: null,
            name: "archType",
            value: "high",
            imageUrl: "/formAssets/archType/high.png"
        }
    ],
    pace: [
        {
            title: "3:00-4:00min/km",
            subHeading: null,
            name: "pace",
            value: "3:00-4:00min/km"
    
        },
        {
            title: "4:00-5:00min/km",
            subHeading: null,
            name: "pace",
            value: "4:00-5:00min/km"
    
        },
        {
            title: "5:00-6:00min/km",
            subHeading: null,
            name: "pace",
            value: "5:00-6:00min/km"
    
        },
        {
            title: "6:00-7:00min/km",
            subHeading: null,
            name: "pace",
            value: "6:00-7:00min/km"
    
        },
        {
            title: "7:00-8:00min/km",
            subHeading: null,
            name: "pace",
            value: "7:00-8:00min/km"
    
        }
        ,
        {
            title: ">8:00min/km",
            subHeading: null,
            name: "pace",
            value: ">8:00min/km"
    
        }
    ],
    experience: [
        {
            title: "Newbie",
            subHeading: "(0-1 years)",
            name: "experience",
            value: "newbie"
    
        },
        {
            title: "Amateur",
            subHeading: "(1-2 years)",
            name: "experience",
            value: "amateur"
    
        },
        {
            title: "Intermediate",
            subHeading: "(2-4 years)",
            name: "experience",
            value: "intermediate"
    
        },
        {
            title: "Advanced",
            subHeading: "(4+ years)",
            name: "experience",
            value: "advanced"
    
        }
    ],
    gender: [
        {
            title: "Male",
            subHeading: null,
            name: "gender",
            value: "male"
    
        },
        {
            title: "Female",
            subHeading: null,
            name: "gender",
            value: "female"
    
        }
    ],
    goal: [
        {
            title: "5km",
            subHeading: null,
            name: "goal",
            value: "5km"
    
        },
        {
            title: "10km",
            subHeading: null,
            name: "goal",
            value: "10km"
    
        },
        {
            title: "21km",
            subHeading: "(half-marathon)",
            name: "goal",
            value: "21km"
    
        },
        {
            title: "42km",
            subHeading: "(full-marathon)",
            name: "goal",
            value: "42km"
    
        },
        {
            title: "42km+",
            subHeading: "(ultra-marathon)",
            name: "goal",
            value: "42km+"
    
        }
        ,
        {
            title: "I don't have a distance goal",
            subHeading: null,
            name: "goal",
            value: "no distance goal"
    
        }
    ],
    height: [
        {
            title: ">121cm",
            subHeading: null,
            name: "height",
            value: ">121cm"
    
        },
        {
            title: "121-140cm",
            subHeading: null,
            name: "height",
            value: "121-140cm"
    
        },
        {
            title: "141-160cm",
            subHeading: null,
            name: "height",
            value: "141-160cm"
    
        },
        {
            title: "161-180cm",
            subHeading: null,
            name: "height",
            value: "161-180cm"
    
        },
        {
            title: "181-200cm",
            subHeading: null,
            name: "height",
            value: "181-200cm"
    
        },
        {
            title: ">200cm",
            subHeading: null,
            name: "height",
            value: ">200cm"
    
        }
    ],
    injuryLocation: [
        {
            title: "Foot",
            subHeading: null,
            name: "injuryLocation",
            value: "foot"
    
        },
        {
            title: "Knee",
            subHeading: null,
            name: "injuryLocation",
            value: "knee"
    
        },
        {
            title: "Hip",
            subHeading: null,
            name: "injuryLocation",
            value: "hip"
    
        },
        {
            title: "Lower leg",
            subHeading: "(Calf/Achilles/Shin)",
            name: "injuryLocation",
            value: "lower-leg"
    
        },
        {
            title: "Ankle",
            subHeading: null,
            name: "injuryLocation",
            value: "ankle"
    
        },
        {
            title: "Quad",
            subHeading: null,
            name: "injuryLocation",
            value: "quad"
    
        },
        {
            title: "Hamstring",
            subHeading: null,
            name: "injuryLocation",
            value: "hamstring"
    
        },
        {
            title: "Back",
            subHeading: null,
            name: "injuryLocation",
            value: "back"
    
        }
    ],
    injuryType: {
        back: [
            {
                title: "Muscle strain",
                subHeading: null,
                name: "injuryType",
                value: "muscle strain"
        
            },
            {
                title: "Herniated/Bulging Disc",
                subHeading: null,
                name: "injuryType",
                value: "herniated/bulging disc"
        
            },
            {
                title: "Sciatica",
                subHeading: null,
                name: "injuryType",
                value: "skeletal irregularities"
        
            },
            {
                title: "Skeletal Irregularities",
                subHeading: null,
                name: "injuryType",
                value: "sciatica"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            },
        ],
        'lower-leg': [
            {
                title: "Achilles Tendonitis",
                subHeading: null,
                name: "injuryType",
                value: "achilles tendonitis"
        
            },
            {
                title: "Shin splints",
                subHeading: null,
                name: "injuryType",
                value: "shin splints"
        
            },
            {
                title: "Calf strain",
                subHeading: null,
                name: "injuryType",
                value: "calf strain"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            }
        ],
        knee: [
            {
                title: "IT Band Syndrome",
                subHeading: null,
                name: "injuryType",
                value: "it band syndrome"
        
            },
            {
                title: "Runners knee",
                subHeading: null,
                name: "injuryType",
                value: "runners knee"
        
            },
            {
                title: "Patellar Tendonitis",
                subHeading: null,
                name: "injuryType",
                value: "patellar tendonitis"
        
            },
            {
                title: "Kneecap Bursitis",
                subHeading: null,
                name: "injuryType",
                value: "kneecap bursitis"
        
            },
            {
                title: "ACL and PCL injuries",
                subHeading: null,
                name: "injuryType",
                value: "acl or pcl"
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            }
        ],
        foot: [
            {
                title: "Plantar Fasciitis",
                subHeading: null,
                name: "injuryType",
                value: "plantar fasciitis"
        
            },
            {
                title: "Metatarsalgia",
                subHeading: null,
                name: "injuryType",
                value: "metatarsalgia"
        
            },
            {
                title: "Stress fracture",
                subHeading: null,
                name: "injuryType",
                value: "stress fracture"
        
            },
            {
                title: "Morton's Nueroma",
                subHeading: null,
                name: "injuryType",
                value: "morton's neuroma"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            }
        ],
        hip: [
            {
                title: "Illiotibial Band Syndrome",
                subHeading: "(ITBS)",
                name: "injuryType",
                value: "illiotibial band syndrome"
        
            },
            {
                title: "Hip Flexor Strain",
                subHeading: null,
                name: "injuryType",
                value: "hip flexor strain"
        
            },
            {
                title: "Stress Fracture",
                subHeading: null,
                name: "injuryType",
                value: "stress fracture"
        
            },
            {
                title: "Piriformis Syndrome",
                subHeading: null,
                name: "injuryType",
                value: "piriformis syndrome"
        
            },
            {
                title: "Hip impingement",
                subHeading: null,
                name: "injuryType",
                value: "hip impingement"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            },
        ],
        ankle: [
            {
                title: "Sprain",
                subHeading: null,
                name: "injuryType",
                value: "sprain"
        
            },
            {
                title: "Tarsal Tunnel Syndrom",
                subHeading: "TTS",
                name: "injuryType",
                value: "tarsal tunnel syndrome"
        
            },
            {
                title: "Tendinopathy",
                subHeading: null,
                name: "injuryType",
                value: "tendinopathy"
        
            },
            {
                title: "Fracture",
                subHeading: null,
                name: "injuryType",
                value: "fracture"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            },
        ],
        quad: [
            {
                title: "Muscle strain",
                subHeading: null,
                name: "injuryType",
                value: "muscle strain"
        
            },
            {
                title: "Tendonitis",
                subHeading: null,
                name: "injuryType",
                value: "tendonitis"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            },
        ],
        hamstring: [
            {
                title: "Muscle strain",
                subHeading: null,
                name: "injuryType",
                value: "muscle strain"
        
            },
            {
                title: "Tendonitis",
                subHeading: null,
                name: "injuryType",
                value: "tendonitis"
        
            },
            {
                title: "Other",
                subHeading: null,
                name: "injuryType",
                value: "other"
        
            },
        ]
    },
    weeklyDistance: [
        {
            title: "0-10km",
            subHeading: null,
            name: "weeklyDistance",
            value: "0-10km"
    
        },
        {
            title: "10-20km",
            subHeading: null,
            name: "weeklyDistance",
            value: "10-20km"
    
        },
        {
            title: "20-40km",
            subHeading: null,
            name: "weeklyDistance",
            value: "20-40km"
    
        },
        {
            title: "40-60km",
            subHeading: null,
            name: "weeklyDistance",
            value: "40-60km"
    
        },
        {
            title: "60-100km",
            subHeading: null,
            name: "weeklyDistance",
            value: "60-100km"
    
        }
        ,
        {
            title: ">100km",
            subHeading: null,
            name: "weeklyDistance",
            value: ">100km"
    
        }
    ],
    weight: [
        {
            title: "30-40kg",
            subHeading: null,
            name: "weight",
            value: "30-40kg"
    
        },
        {
            title: "41-50kg",
            subHeading: null,
            name: "weight",
            value: "41-50kg"
    
        },
        {
            title: "51-60kg",
            subHeading: null,
            name: "weight",
            value: "51-60kg"
    
        },
        {
            title: "61-70kg",
            subHeading: null,
            name: "weight",
            value: "61-70kg"
    
        },
        {
            title: "71-80kg",
            subHeading: null,
            name: "weight",
            value: "71-80kg"
    
        },
        {
            title: "81-90kg",
            subHeading: null,
            name: "weight",
            value: "81-90kg"
    
        },
        {
            title: "91-100kg",
            subHeading: null,
            name: "weight",
            value: "91-100kg"
    
        },
        {
            title: ">100kg",
            subHeading: null,
            name: "weight",
            value: ">100kg"
    
        }
    ],
    footStrike: [
        {
            title: "Heel Strike",
            subHeading: null,
            name: "footStrike",
            value: "heel strike",
            imageUrl: "/formAssets/footstrike/heel.png"
    
        },
        {
            title: "Forefoot Strike",
            subHeading: null,
            name: "footStrike",
            value: "forefoot strike",
            imageUrl: "/formAssets/footstrike/forefoot.png"
    
        },
        {
            title: "Midfoot Strike",
            subHeading: null,
            name: "footStrike",
            value: "midfoot strike",
            imageUrl: "/formAssets/footstrike/midfoot.png"
    
        }
    ],
    footWidth: [
        {
            title: "Narrow",
            subHeading: null,
            name: "footWidth",
            value: "narrow",
            imageUrl: "/formAssets/footWidth/narrow.png"
    
        },
        {
            title: "Normal",
            subHeading: null,
            name: "footWidth",
            value: "normal",
            imageUrl: "/formAssets/footWidth/normal.png"
    
        },
        {
            title: "Wide",
            subHeading: null,
            name: "footWidth",
            value: "wide",
            imageUrl: "/formAssets/footWidth/wide.png"
    
        },
        {
            title: "Extra-wide",
            subHeading: null,
            name: "footWidth",
            value: "extra-wide",
            imageUrl: "/formAssets/footWidth/extra-wide.png"
    
        }
    ],
    pronation: [
        {
            title: "Extreme overpronation",
            subHeading: null,
            name: "pronation",
            value: "severe overpronation",
            imageUrl: "/formAssets/pronationType/severe_over-pronation.png"
    
        },
        {
            title: "Overpronation",
            subHeading: null,
            name: "pronation",
            value: "overpronation",
            imageUrl: "/formAssets/pronationType/mild_over-pronation.png"
    
        },
        {
            title: "Neutral",
            subHeading: null,
            name: "pronation",
            value: "neutral",
            imageUrl: "/formAssets/pronationType/neutral.png"
    
        }
        ,
        {
            title: "Underpronation / Supination",
            subHeading: null,
            name: "pronation",
            value: "supination",
            imageUrl: "/formAssets/pronationType/supination.png"
    
        }
    ],
    tightnessLocation: [
        {
            title: "Foot",
            subHeading: null,
            name: "tightnessLocation",
            value: "foot"
    
        },
        {
            title: "Knee",
            subHeading: null,
            name: "tightnessLocation",
            value: "knee"
    
        },
        {
            title: "Hip",
            subHeading: null,
            name: "tightnessLocation",
            value: "hip"
    
        },
        {
            title: "Lower leg",
            subHeading: "(Calf/Achilles/Shin)",
            name: "tightnessLocation",
            value: "lower-leg"
    
        },
        {
            title: "Ankle",
            subHeading: null,
            name: "tightnessLocation",
            value: "ankle"
    
        },
        {
            title: "Quad",
            subHeading: null,
            name: "tightnessLocation",
            value: "quad"
    
        },
        {
            title: "Hamstring",
            subHeading: null,
            name: "tightnessLocation",
            value: "hamstring"
    
        },
        {
            title: "Back",
            subHeading: null,
            name: "tightnessLocation",
            value: "back"
    
        }
    ]
}

export { runningProfileOptions }