import { Outlet, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../utils/ContextProvider.jsx";
import FlashAlert from "../../components/Alert/index.jsx";
import Loader from "../../components/Loader/index.jsx";
import Footer from "../../components/Footer/index.jsx";
import { ScrollRestoration } from "react-router-dom";

export default function FormPage() {

    const { isLoading, isAlert, setAlertState, setIsAlert, setIsLoading, requestUrl, partner, setPartner } = useAppContext()

    return (
        <div>
            <Outlet context={[partner]}/>   
            {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
            {isAlert.success ? <FlashAlert severity={"success"} message={isAlert.success} setAlertState={setAlertState} />: null}
            {!partner ? <Footer /> : null} 
            <ScrollRestoration />
        </div>
    )
}