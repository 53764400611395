import Tooltip from "@mui/material/Tooltip"
import FormBodyIntro from "./FormBodyIntro"
import FormBodySelection from "./FormBodySelection"
import FormBodySubmit from "./FormBodySubmit"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './index.css'

export default function FormBody({ heading, subHeading, formBodyType, selectionOptions, isAutoComplete, completeTime, handleClick, toolTip = null }) {
    return (
        <div className="container pt-5 main-form-body d-flex flex-column">
            <div className="row">
                <div className="col-auto text-center text-sm-start">
                    <h2>{heading}</h2>
                </div>
                {toolTip ? 
                <div className="col-auto px-0 mx-auto mx-md-0">
                    <Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={toolTip}>
                        <InfoOutlinedIcon/>
                    </Tooltip>
                </div>
                : null}
            </div>
            <div className="row mt-3">
                <div className="col my-auto text-center text-sm-start">
                    <p>{subHeading}</p>
                </div>
            </div>

            {formBodyType === "Intro" ? <FormBodyIntro completeTime={completeTime} /> : formBodyType === "Selection" ? <FormBodySelection selectionOptions={selectionOptions} isAutoComplete={isAutoComplete} handleClick={handleClick} /> : <FormBodySubmit />}
        </div>
    )
}