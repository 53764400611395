import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import  Divider  from "@mui/material/Divider"
import  Button  from "@mui/material/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography  from "@mui/material/Typography";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import { FormGroup, FormControlLabel, Checkbox, MenuItem, FormControl, InputLabel, Select, RadioGroup } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import Badge from "@mui/material/Badge";
import Radio from "@mui/material/Radio"
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';


export default function FilterPanel({ filters, setFilters = null, shoes = null, setShoes = null, setBudgetFilterState, setBrandsFilterState, clearFilters = null, setRecommendScoreFilterState, setAllShoesFilterState }) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl } = useAppContext()

    const [expanded, setExpanded] = useState(false);

    const [allBrands, setAllBrands] = useState([])


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    useEffect(() => {
        var url = `${requestUrl}/api/brands`

        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setAllBrands(res.data.allBrands)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
        })
    }, [])



    return (
        <Badge color="error" badgeContent=" " invisible={(filters.brands && filters.brands.length > 0) || filters.price.highPrice !== 550 || filters.price.lowPrice !== 0 || filters.recommendScore.minScore !== 0 ? false : true} variant="standard" sx={{display: 'inline'}}>
            <div className="row">
                

                
                    <div className="col filters-column py-3 px-3 card">

                        <div className="row mb-4 align-items-center">
                            <div className="col mx-2">
                                <h2 className="my-0">Filter shoes</h2>
                            </div>
                            {(filters.brands && filters.brands.length > 0) || filters.price.highPrice !== 550 || filters.price.lowPrice !== 0 || filters.recommendScore.minScore !== 0 ?
                            <div className="col-auto py-2 mx-2">
                                <a onClick={clearFilters}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear</p></a>
                            </div>
                            : null} 
                        </div>


                        {/* Brands filter row */}
                        <div className="row">
                            
                            <div className="col">
                                <Accordion expanded={expanded === 'brandpanel'} onChange={handleChange('brandpanel')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="brandpanelbh-content"
                                        id="brandpanelbh-header"
                                    >
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <StorefrontIcon/>
                                            </div>
                                            <div className="col-auto px-1">
                                                Brand
                                            </div>
                                            <div className="col-auto px-1">
                                                {filters.brands && filters.brands.length > 0 ? `(${filters.brands.length})` : null }
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                <FormGroup>
                                        {allBrands?.map((brand) => (
                                            <FormControlLabel key={`${brand.slug}Checkbox`} value={brand.name} control={filters.brands.includes(brand.name) ? <Checkbox value={brand.title} checked={true} onClick={setBrandsFilterState} /> : <Checkbox value={brand.title} checked={false} onClick={setBrandsFilterState} /> } label={brand.name} />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                                </Accordion>
                            </div>

                        </div>

                        {/* Price filter row */}
                        <div className="row mt-1">
                            <div className="col">
                            <Accordion expanded={expanded === 'budgetpanel'} onChange={handleChange('budgetpanel')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="budgetpanelbh-content"
                                id="budgetpanelbh-header"
                            >
                                <div className="row">
                                        <div className="col-auto">
                                            <AttachMoneyIcon/>
                                        </div>
                                        <div className="col-auto px-1">
                                            Price
                                        </div>
                                        <div className="col-auto px-1">
                                            {filters.price.lowPrice !== 0 || filters.price.highPrice !== 550 ? '(1)' : null}
                                        </div>

                                    </div>
                            </AccordionSummary>

                            <AccordionDetails>

                            <FormGroup>
                                <div className="row mb-3">
                                    <div className="col">
                                    <FormControl fullWidth>
                                    <InputLabel id="low-price-label">Min</InputLabel>
                                        <Select
                                            defaultValue={filters.price.lowPrice? filters.price.lowPrice :0}
                                            labelId="low-price-label"
                                            id="low-price-select"
                                            name="lowPrice"
                                            value={filters.price.lowPrice? filters.price.lowPrice : 0}
                                            label="Low"
                                            onChange={setBudgetFilterState}
                                        >
                                            <MenuItem value={0}>Any</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                            <MenuItem value={150}>150</MenuItem>
                                            <MenuItem value={175}>175</MenuItem>
                                            <MenuItem value={200}>200</MenuItem>
                                            <MenuItem value={225}>225</MenuItem>
                                            <MenuItem value={250}>250</MenuItem>
                                            <MenuItem value={275}>275</MenuItem>
                                            <MenuItem value={300}>300</MenuItem>
                                            <MenuItem value={350}>350</MenuItem>
                                            <MenuItem value={450}>450</MenuItem>
                                            <MenuItem value={500}>500</MenuItem>
                                        </Select>
                            </FormControl>  
                                    </div>
                                </div>
                            

                            <FormControl fullWidth>
                                <InputLabel id="high-price-label">Max</InputLabel>
                                    <Select
                                        defaultValue={filters.price.highPrice? filters.price.highPrice : 550}
                                        labelId="high-price-label"
                                        id="high-price-select"
                                        name="highPrice"
                                        value={filters.price.highPrice? filters.price.highPrice : 550}
                                        label="Highest"
                                        onChange={setBudgetFilterState}
                                    >
                                        <MenuItem value={550}>Any</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={150}>150</MenuItem>
                                        <MenuItem value={175}>175</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={225}>225</MenuItem>
                                        <MenuItem value={250}>250</MenuItem>
                                        <MenuItem value={275}>275</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={350}>350</MenuItem>
                                        <MenuItem value={450}>450</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                    </Select>
                            </FormControl>    
                            </FormGroup>
                            </AccordionDetails>
                            </Accordion>
                            </div>
                        </div>

                        {/* Match % filter row */}
                        <div className="row mt-1">
                            <div className="col">
                                <Accordion expanded={expanded === 'scorepanel'} onChange={handleChange('scorepanel')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="scorepanelbh-content"
                                    id="scorepanelbh-header"
                                >
                                    <div className="row">
                                            <div className="col-auto">
                                                <RecommendOutlinedIcon/>
                                            </div>
                                            <div className="col-auto px-1">
                                                Match %
                                            </div>
                                            <div className="col-auto px-1">
                                                {filters.recommendScore.minScore !== 0 ? '(1)' : null}
                                            </div>

                                        </div>
                                </AccordionSummary>

                                <AccordionDetails>

                                    <div className="row mb-3">
                                        <div className="col">
                                        <FormControl fullWidth>
                                        <InputLabel id="min-score-label">Minimum score</InputLabel>
                                            <Select
                                                defaultValue={filters?.recommendScore.minScore}
                                                labelId="min-score-label"
                                                id="min-score-select"
                                                name="minScore"
                                                label='Minimum score'
                                                value={filters?.recommendScore.minScore}
                                                onChange={setRecommendScoreFilterState}
                                            >
                                                <MenuItem value={0}>0</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={30}>30</MenuItem>
                                                <MenuItem value={40}>40</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={60}>60</MenuItem>
                                                <MenuItem value={70}>70</MenuItem>
                                                <MenuItem value={80}>80</MenuItem>
                                                <MenuItem value={90}>90</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                            </Select>
                                </FormControl>  
                                        </div>
                                    </div>

                                </AccordionDetails>
                                </Accordion>
                            
                            </div>
                        </div>

                        {/* Match % filter row */}
                        <div className="row mt-1">
                            <div className="col">
                                <Accordion expanded={expanded === 'currentShoespanel'} onChange={handleChange('currentShoespanel')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="currentShoespanellbh-content"
                                    id="currentShoespanellbh-header"
                                >
                                    <div className="row">
                                            <div className="col-auto">
                                                <DoorSlidingOutlinedIcon />
                                            </div>
                                            <div className="col-auto px-1">
                                                Current shoes
                                            </div>
                                            <div className="col-auto px-1">
                                                {filters.allShoes? '(1)' : null}
                                            </div>

                                        </div>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <FormControl>
                                        <RadioGroup defaultValue={true}>
                                            <FormControlLabel key={`currentShoesTrueCheckbox`} control={<Radio value={true} onClick={(evt) => setAllShoesFilterState(evt, true)}  /> } label={"Recommended"} />
                                            <FormControlLabel key={`currentShoesFalseCheckbox`} control={<Radio value={false} onClick={(evt) => setAllShoesFilterState(evt, false)} /> }  label={"Include all current shoes"} />
                                        </RadioGroup>  
                                    </FormControl>
                                </AccordionDetails>
                                </Accordion>
                            
                            </div>
                        </div>
                    </div> 

            </div>
        </Badge>
    )   
}