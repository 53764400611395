import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SortIcon from '@mui/icons-material/Sort';
import ListItemIcon from '@mui/material/ListItemIcon'
import { useAppContext } from '../../../../utils/ContextProvider.jsx';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';

export default function SortButton( { sort = null, setSort = null }) {

    const { trackEvent } = useAppContext()

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        
    };

    const handleSortClick = (evt) => {
        setAnchorEl(null);
        if (evt.target.attributes.value) {
          var sort = evt.target.attributes.value.value
          trackEvent('Sort shoes', {sortCriteria: sort})
          setSort(sort)
        }

    };


  return (
    <div>
      <Button
        id="demo-positioned-button"
        endIcon={<SortIcon />}
        variant='outlined'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuButtonClick}
      >
        Sort: {sort}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleSortClick}
      >
        <MenuItem onClick={handleSortClick} selected={sort === 'recommended' ? true : false} key={'recommendedSort'} value="recommended"> 
            <ListItemIcon>
              <StarBorderOutlinedIcon/>
            </ListItemIcon>
            Recommended
        </MenuItem>
        <MenuItem onClick={handleSortClick} selected={sort === 'popularity' ? true : false} key={'popularitySort'} value={'popularity'}>
            <ListItemIcon>
              <PhoneInTalkOutlinedIcon/>
            </ListItemIcon>
            Popularity
        </MenuItem>
        <MenuItem onClick={handleSortClick} selected={sort === 'price' ? true : false} key={'priceSort'} value={'price'}>
            <ListItemIcon>
              <AttachMoneyOutlinedIcon/>
            </ListItemIcon>
          Price
        </MenuItem>
        <MenuItem onClick={handleSortClick} selected={sort === 'new' ? true : false} key={'newSort'} value={'new'}>
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
          New
        </MenuItem>
        <MenuItem onClick={handleSortClick} selected={sort === 'ratings' ? true : false} key={'ratingsSort'} value={'ratings'}>
        <ListItemIcon>
              <ThumbUpOutlinedIcon/>
            </ListItemIcon>
          Ratings
        </MenuItem>
        <MenuItem onClick={handleSortClick} selected={sort === 'cushion' ? true : false} key={'cushionSort'} value={'cushion'}>
        <ListItemIcon>
              <BedOutlinedIcon/>
            </ListItemIcon>
          Cushion
        </MenuItem>
        <MenuItem onClick={handleSortClick} selected={sort === 'weight' ? true : false} key={'weightSort'} value={'weight'}>
        <ListItemIcon>
              <ScaleOutlinedIcon/>
            </ListItemIcon>
          Weight
        </MenuItem>
      </Menu>
    </div>
  );
}