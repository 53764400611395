import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "'2A'-(female) or 'B'-(male)",
        subHeading: "(Narrow)",
        name: "footWidth",
        value: "narrow"

    },
    {
        title: "'D'-(female) or '2E'-(male)",
        subHeading: "(Wide)",
        name: "footWidth",
        value: "wide"

    },
    {
        title: "'2E'-(female) or '4E'-(male)",
        subHeading: "(Extra-wide)",
        name: "footWidth",
        value: "extra-wide"

    }
    ,
    {
        title: "I can't find any information",
        subHeading: "(Normal)",
        name: "footWidth",
        value: "normal"

    }
]

export default function FootWidthUnknownPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        navigate('/shoe-finder/running-profile/foot-width/spill-over', {state: {shoeWidth: evt.target.value }})
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Check the label on inside of a pair of running shoes you own, can you see the following inforamtion by the size?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}