import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "They tilt inwards a lot, and there is a lot of wear on the inside of the sole of the shoe...",
        subHeading: "(severe overpronation)",
        name: "pronation",
        value: "severe overpronation",
        imageUrl: "/formAssets/pronationType/unknown/severe_over-pronation.png"

    },
    {
        title: "They tilt inwards a little bit, the inside of the sole of the shoe isn't worn too badly...",
        subHeading: "(overpronation)",
        name: "pronation",
        value: "overpronation",
        imageUrl: "/formAssets/pronationType/unknown/mild_over-pronation.png"

    },
    {
        title: "They sit flat on the ground, and don't tilt inwards or outwards..",
        subHeading: "(neutral pronation)",
        name: "pronation",
        value: "neutral",
        imageUrl: "/formAssets/pronationType/unknown/neutral.png"

    }
    ,
    {
        title: "They tilt outwards when sitting flat on the ground, most of the wear is on the outside of the sole...",
        subHeading: "(under pronation/ supination)",
        name: "pronation",
        value: "supination",
        imageUrl: "/formAssets/pronationType/unknown/supination.png"

    }
]

export default function PronationUnknownPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var name = ""
        var value = ""
        if (!evt.target.value) {
            if (evt.target.parentNode.value) {
                value = evt.target.parentNode.value
                name = evt.target.parentNode.name
            } else {
                value = evt.target.parentNode.parentNode.value
                name = evt.target.parentNode.parentNode.name
            }
            
        } else {
            value = evt.target.value
            name = evt.target.name
        }
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        var newRunningProfileObject = {...runningProfileObject, [name]: value}
        var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
        localStorage.setItem("runningProfile", newRunningProfileObjectString)
        navigate('/shoe-finder/running-profile/arch')
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"Lay a well-worn pair of running shoes flat on the ground, and observe how they sit.. "} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}