import TextField  from "@mui/material/TextField"
import { useState } from "react"
import Button from "@mui/material/Button"
import axios from 'axios'
import { useAppContext } from "../../../utils/ContextProvider.jsx"
import FlashAlert from "../../../components/Alert"
import CircularProgress from "@mui/material/CircularProgress"
import * as Sentry from "@sentry/react";

export default function ForgotPassword() {

    const { isLoading, isAlert, setAlertState, setIsAlert, setIsLoading, trackEvent, requestUrl, isProcessing, setProcessing } = useAppContext()

    const [email, setEmail] = useState("")

    var handleSubmit = (evt) => {
        trackEvent('Send password reset email')
        const url = `${requestUrl}/api/users/password/forgot`
        setProcessing(true)
        axios({
            url: url,
            method: "POST",
            data: {
                email: email,
            },
            withCredentials: true
        })
        .then((res) => {
            setProcessing(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    success: `We've sent you a verification email. Please check your email. `
                }
            })
        })
        .catch((err) => {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setProcessing(false)
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
        })
    }

    return (
    <div className="row py-5 my-3 align-self-center">
        {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
        {isAlert.success ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
        <div className="col-11 col-md-10 col-xl-8 mx-auto form-container">

            <div className="row">
                <div className="col-7 mx-auto text-center pt-3">
                <a href="/"><img className="img img-fluid" src="/logos/logo-primary.svg" alt=""/></a>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col mx-auto text-center pt-2">
                <h2>Reset your password</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-10 mx-auto text-center pt-3">
                    <p>We'll email you a link to reset your password to verify its you. If you can't find your email, make sure to check your promotions or spam folder.</p>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="row mx-auto">
                        <div className="col-10 mx-auto">
                            <div className="row my-4">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <div className="col">
                                                <TextField fullWidth id="email" type="email" name="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)} label="Email" variant="outlined" />
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-auto mx-auto">
                                    <Button
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        onClick={handleSubmit}
                                        disabled={isProcessing == true ? true : false}
                                        endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                    >Reset my password
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}