import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import { runningProfileOptions } from "../../../../utils/ProfileOptions"
import { useOutletContext } from "react-router-dom"


export default function GoalPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    var [partner] = useOutletContext()


    const handleClick = (evt) => {
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        // Skip current and past shoe pages if its partner view
        if (partner) {
            var newRunningProfileObject = {...runningProfileObject, [evt.target.name]: evt.target.value}
            var objectWithCurrentShoes = {...newRunningProfileObject, ['currentShoe']: []}
            var objectWithPastShoes = {...objectWithCurrentShoes, ['pastShoe']: []}
            var finalRunningProfileObjectString = JSON.stringify(objectWithPastShoes)
            localStorage.setItem("runningProfile", finalRunningProfileObjectString)
            navigate('/shoe-finder/running-profile/injury')
            

        } else {
            var newRunningProfileObject = {...runningProfileObject, [evt.target.name]: evt.target.value}
            var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
            localStorage.setItem("runningProfile", newRunningProfileObjectString)
            navigate('/shoe-finder/running-profile/current-shoe')
        }

        
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"What is your next running distance goal?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={runningProfileOptions.goal}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}