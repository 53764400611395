import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../utils/ContextProvider.jsx';

export default function DashboardHeader( {heading = null, subHeading = null, breadcrumb = null, buttons = []} ) {

    var { isLoading, setIsLoading } = useAppContext()

    const navigate = useNavigate()

    const { state } = useLocation() 

    function handleClick(link) {
        navigate(link)
    }

    if (isLoading) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className="container-fluid dashboard-header mt-5 mb-3">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 col-lg-10 mx-auto">
                                <div className="row mb-4">
                                    <div role="presentation">
                                        <Breadcrumbs aria-label="breadcrumb"> 
                                            {breadcrumb?.map((breadcrumb, index) => (
                                               <Link
                                                    underline='hover'
                                                    style={{cursor: 'pointer'}}
                                                    color='inherit'
                                                    onClick={() => handleClick(breadcrumb.href, breadcrumb.state)}
                                                    key={`${breadcrumb.title}_title`}
                                               >
                                               {breadcrumb.title?.replace('_', ' ')}
                                               </Link> 
                                            ))}
                                        </Breadcrumbs>
                                    </div>
                                </div>
                                {heading?
                                <div className="row">
                                    <div className="col text-center text-md-start">
                                        <h2>{heading?.replace('_', ' ')}</h2>
                                    </div>
                                </div>
                                : null }
    
                                {subHeading?
                                <div className="row mt-1">
                                    <div className="col text-center text-md-start">
                                        <p className="mb-2 dashboard-text">{subHeading?.replace('_', ' ')}</p>
                                    </div>
                                </div>
                                : null}
    
                                {buttons?
                                <div className="row align-items-center mt-2">
                                        {buttons?.map((button, index) => {
                                            return (
                                                <div className="col-auto mx-auto mx-md-0 text-center form-card-button" key={`${index}Button`}>
                                                <a onClick={button.function} className="btn form-card-btn px-0"><h4>{button.text} <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="10" stroke="#1F8BF4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M17 12H7M17 12L13 8M17 12L13 16" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg></h4></a>  
                                                </div>
                                            )
                                        }
                                        
                                            
                                    )}
                                </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
        )   
    }

    
}