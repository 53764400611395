import Card from '@mui/material/Card';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ButtonBase, Chip, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChipComponent from '../../../Chip';
import Divider from '@mui/material/Divider';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpAlt'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import StarRateIcon from '@mui/icons-material/StarRate';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAppContext } from '../../../../utils/ContextProvider.jsx';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState } from 'react';
import PurchaseShoeModal from '../../Modal/PurchaseShoeModal';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

export default function ShoeCard({ recommendationId = null, shoe = null, preferences = null, currentShoe = null, requirements = null }) {

    var [ user ] = useOutletContext()
    
    var { trackEvent } = useAppContext()

    var colorwayIndex = shoe.shoe.colorways?.indexOf(shoe.shoe.colorways?.filter((colorway) => colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)))[0]) 

    if (colorwayIndex == -1) {
        colorwayIndex = 0
    }

    var [shoeColorway, setShoeColorway] = useState(shoe.shoe.colorways?.length > 0 ? shoe.shoe.colorways[colorwayIndex] : null)

    var [purchaseShoeModalOpen, setPurchaseShoeModalOpen] = useState(false)

    const navigate = useNavigate()

    const handleClick = (evt, tabIndex) => {
        var url = `/dashboard/recommendations/${recommendationId}/shoes/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}`
        navigate(url, {state: {shoe: shoe, preferences: preferences, requirements: requirements, shoeColorway: shoeColorway, tabIndex: tabIndex}})
    }



    return (
        <Card 
            key={shoe.shoe_id}
            style={currentShoe ? { border: "2px solid #1F8BF4" }: null}
            className='px-2'
            >
            {/* Match pill */}
            <div key={`${shoe.shoe._id}_match_pil`} className="row pt-3  mt-1 mb-3">
                <div className="col-auto mx-auto">
                    <Tooltip 
                        arrow 
                        disableFocusListener 
                        enterTouchDelay={0} 
                        leaveTouchDelay={5000}
                        title={
                        <span>
                            {shoe.criteria.sort((a,b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                var string = criteria.trait.split(/(?=[A-Z])/);
                                var finalString = string.join(' ').toLowerCase()
                                if (requirements[criteria.trait].reasoning.length > 0) {
                                    return (
                                        <div key={`${criteria.trait}TraitRow`} className="row">
                                            <div className="col">
                                                {criteria.pass == 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {Array.isArray(shoe.shoe[criteria.trait]) ? shoe.shoe[criteria.trait].join(', ') : shoe.shoe[criteria.trait]}
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            <a style={{color: 'white', cursor: 'pointer', textDecoration: 'underline'}} onClick={(evt) => handleClick(evt, 1)}>Learn more</a>
                        </span>
                        }
                    >
                        <span>
                            <ChipComponent
                                icon={
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                        ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                        ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                        ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                    
                                }
                                label={`${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match`}
                                color={
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                    ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                    
                                }
                                variant={(shoe.score / shoe.maxScore) < 1.0 ? 'outlined' : null}
                                href={`/dashboard/recommendations/${recommendationId}/${shoe.shoe.brand.slug}_${shoe.shoe.model}_${shoe.shoe.version}#matching-information`}
                            />
                        </span>
                    </Tooltip>
                   
                </div>
            </div>


            {/* Brand image */}
            <div key={`${shoe.shoe._id}_brandImage=`} className="row">
                <div className="col-9 mx-auto text-center">
                    <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoe.brand.slug}.png`} alt="" />
                </div>
            </div>


            {/* Shoe image */}
            <div className="row pt-2">
                <div className="col-12 mx-auto text-center">
                    <img  style={{cursor: 'pointer'}} onClick={handleClick} loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${shoeColorway?.colorwayCode}.png`} alt="" />
                </div>
            </div>

            <CardContent className='py-3'>

            
            

            

            <div className="row mt-2 px-2 justify-content-center justify-content-md-start">
                {shoe.shoe.colorways?.map((colorway, index)=> {
                    var widthAvailable = shoe.shoe.shoeShape !== 'foot shaped' ? colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)) : true
                    return (
                        <div className="col-3 col-sm-2 mx-1 my-1" style={{cursor: 'pointer'}} key={`${shoe.shoe._id}Colorway${index}`} onClick={(evt) => setShoeColorway(colorway)}>
                            <Tooltip title={ widthAvailable ? `${colorway.colorwayName}` : `${colorway.colorwayName} (not available in ${requirements.width.traits.join(' or ')})`}>
                                <div className="row">
                                    <div className="col py-1 py-md-2" style={{border: shoeColorway.colorwayCode === colorway.colorwayCode ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') : '1px solid #D9D9D9', borderRadius: '0.5em', opacity: widthAvailable ? '1' : '0.5'}}>
                                        <img className='img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} alt="" />
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                        
                    )
                })}
            </div>

            {/* Label (new, best for, most cushioned etc) */}
            {currentShoe ?
                <div className="row mt-3">
                    <div className="col-auto">
                        <ChipComponent 
                            color='info'
                            size='small'
                            label="Owned"
                            />
                    </div>  
                </div>              
                : 
                null
                }

            <div className="row mt-3">
                <div className="col-auto">
                    <h2 style={{cursor: 'pointer'}} onClick={handleClick}>{shoe.shoe.brand.name} {(shoe.shoe.model).charAt(0).toUpperCase() + shoe.shoe.model.slice(1)} {shoe.shoe.version}</h2>
                </div>
            </div>

            <div className="row mt-1">
                <div className="col-auto">
                    <h4>${shoe.shoe.msrp}</h4>
                </div>
            </div>

            

            

            <div className="row px-2 mt-2">
                <div className="col-auto px-1">
                    <ChipComponent
                                label={`${shoe.shoe.cushion} cushion (${shoe.shoe.stackHeight}mm)`}
                                size='small'
                                color='default'
                            />
                </div>
                <div className="col-auto px-1">
                    <ChipComponent
                                label={`${shoe.shoe.weight} weight`}
                                size='small'
                                color='default'
                            />
                </div>
                <div className="col-auto px-1">
                    <ChipComponent
                                label={`${shoe.shoe.grip} grip`}
                                size='small'
                                color='default'
                            />
                </div>
            </div>

            </CardContent>
            <CardActions>
                <div className="col">
                    <div className="row justify-content-center">
                    
                        <div className="col-auto my-1">
                            <Button size="small" endIcon={<ShoppingCartIcon/>} variant="outlined" onClick={(evt) => trackEvent('Find a retailer', {shoeName: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`})} href={`https://www.google.com/search?q=buy+${shoe?.shoe.brand.name}+${(shoe?.shoe.model)?.charAt(0).toUpperCase() + shoe?.shoe.model.slice(1)}+${shoe?.shoe.version}+${user?.runningProfile.footWidth === 'wide' ? (user?.runningProfile.gender === 'male' ? `2E+width` : `D+width` ) : (user?.runningProfile.footWidth === 'extra-wide' ? (user?.runningProfile.gender === 'male' ? `4E+width` : `2e+width` ) : null)}+${user?.runningProfile.gender} + ${shoeColorway?.colorwayName}`} target="_blank" >Find a retailer</Button>
                            {/* <Button onClick={(evt) => setPurchaseShoeModalOpen(true)} variant='outlined' size="small" endIcon={<ShoppingCartIcon/>}>Buy now</Button> */}
                        </div>
                        <div className="col-auto my-1">
                            <Button onClick={handleClick} variant='outlined' size="small" endIcon={<ArrowRightAltIcon/>}>View Details</Button>
                        </div>
                        
                    </div>
                </div>
                
                
            </CardActions>
             {/* <PurchaseShoeModal openState={purchaseShoeModalOpen} setOpenState={setPurchaseShoeModalOpen} shoe={shoe} colorway={shoeColorway} requirements={requirements} /> */}
        </Card>
       
    )
}