import Modal from "../../../Modal"
import * as React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function RecommendationReasoningModal({openState = null, setOpenState = null, recommendation = null, variable = null, openVariable = null, maxScore = null}) {

    const content = 
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <p>Based on your requirements and preferences, the following criteria have been used to generate your {recommendation?.preferences?.shoeUsage} {recommendation?.preferences?.shoeSurface} recommendation.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        {recommendation?.requirements ? Object.entries(recommendation.requirements).sort((a,b) => a[1].priorityScore? b[1].priorityScore - a[1].priorityScore : null ).map((key, value, index) => {
                            var string = key[0].split(/(?=[A-Z])/).join(' ').toLowerCase();
                            var finalTitleString = string.charAt(0).toUpperCase() + string.slice(1)
                            if (key[1].reasoning && key[1].reasoning.length > 0) {
                                var FinalDescriptionString = ""
                            for (let j = 0; j < key[1].reasoning.length; j++) {
                                FinalDescriptionString += `${key[1].reasoning[j].description}. `
                            }
                            console.log(key[1].priorityScore)
                            return (
                                <div className="row my-1" key={`${finalTitleString}AccordianRow`}>
                                    <div className="col">
                                        <Accordion defaultExpanded={openVariable == key[0] ? true : false}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`${finalTitleString}ReasoningContent`}
                                                id={`${finalTitleString}Header`}
                                                >
                                                    <div className="col">
                                                        <div className="row align-items-center justify-content-between">
                                                            <div className="col-auto">
                                                                <h4 className="my-0">{finalTitleString}</h4>
                                                            </div>
                                                            <div className="col-auto">
                                                                {key[1].priorityScore ?
                                                                    <p style={{textDecoration: 'underline'}} className="my-0">Importance: {key[1].priorityScore / maxScore > 0.15 ? `High` : key[1].priorityScore / maxScore > 0.05 ? 'Medium' : 'Low'}</p>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="row">
                                                    <p>Recommended: <br />- {recommendation.requirements[key[0]].traits.length ? recommendation.requirements[key[0]].traits.reduce((accu, elem) => {return accu === null ? [elem] : [...accu, ', ', elem]} ) : Object.entries(recommendation.requirements[key[0]].traits).map((elem) => `${elem[0]}: ${elem[1]}`).join(', ')} </p>
                                                </div>
                                            <div className="row">
                                                    <div className="col">
                                                        <p>Your needs: {recommendation.requirements[key[0]].reasoning.map((reason, index) => { return <p key={`${key}${index}Description`} className="my-0">- {reason.description}</p> })}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p>Impact: {recommendation.requirements[key[0]].reasoning.map((reason, index) => { return <p key={`${key}${index}Impact`} className="my-0">- {reason.impact} {reason.references.length > 0 ? <span><Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={<React.Fragment>{reason.references?.map((ref) => { return <span><a style={{color: 'white'}} target="_blank" href={ref}>{ref}</a><br /></span> })}</React.Fragment>}><FormatQuoteIcon /></Tooltip></span> : null}</p> })}</p>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
            
                                    </div>
                                </div>
                            )
                        }}) : null}
                        </div>
                    </div>
                    
                </div>

    return (
        <Modal
            heading={'Running shoe recommendation reasoning'}
            content={content}
            openState={openState}
            setOpenState={setOpenState}
        />
    )
}