import { useState } from "react"
import TextField from "@mui/material/TextField"
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import axios from "axios";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from "@mui/material/Button";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../utils/ContextProvider.jsx";
import FlashAlert from "../../../components/Alert/index.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import * as Sentry from "@sentry/react";


export default function ResetPasswordPage() {

    const { isLoading, isAlert, setAlertState, setIsAlert, setIsLoading, requestUrl, setProcessing, isProcessing, trackEvent } = useAppContext()

    const navigate = useNavigate()

    var [searchParams, setSearchParams] = useSearchParams()

    var id = searchParams.get("id")
    var token = searchParams.get("token")

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        password2: ""
    })

    const [showPassword, setShowPassword] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChange = (evt) => {
        const changedField = evt.target.name
        const newValue = evt.target.value

        setFormData(currData => {
            return {
                ...currData,
                [changedField]: newValue
            }
        })
    }

    var handleSubmit = (evt) => {
        if (formData.password !== formData.password2) {
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: "Passwords do not match."
                }
            })
        } else {
            trackEvent('Reset password submitted')
            const url = `${requestUrl}/api/users/password/reset?token=${token}&id=${id}`
            setProcessing(true)
            axios({
                url: url,
                method: "POST",
                data: {
                    email: formData.email,
                    password: formData.password,
                    password2: formData.password2
                },
                withCredentials: true
            })
            .then((res) => {
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        success: `Password successfully reset. Please login again.`
                    }
                })
                setProcessing(false)
                localStorage.clear('isAuthenticated')
                navigate('/auth/login')
            })
            .catch((err) => {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setProcessing(false)
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                navigate('/auth/forgot-password')
            })
            }
        
    }

    return (
    <div className="row py-5 my-3 align-self-center">
        {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
        <div className="col-11 col-md-10 col-xl-8 mx-auto form-container">
            <div className="row">
                <div className="col-7 mx-auto text-center pt-3">
                <a href="/"><img className="img img-fluid" src="/logos/logo-primary.svg" alt=""/></a>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col mx-auto text-center pt-2">
                <h2>Reset your password</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-10 mx-auto text-center pt-3">
                    <p>Set your new password, you'll need to login again after you reset it.</p>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="row mx-auto">
                        <div className="col-10 mx-auto">
                                <div className="row my-4">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="password">New Password</InputLabel>
                                                    <OutlinedInput
                                                        id="password"
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        onChange={handleChange}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        label="Password"
                                                    />
                                                </FormControl>
                                            </div>
                                        </div> 
                                        <div className="row mt-3">
                                            <div className="col">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="password2">Confirm New Password</InputLabel>
                                                    <OutlinedInput
                                                        id="password2"
                                                        name="password2"
                                                        type={showPassword ? 'text' : 'password'}
                                                        onChange={handleChange}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        label="Repeat Password"
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-auto mx-auto">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            onClick={handleSubmit}
                                            disabled={isProcessing == true ? true : false}
                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                        >Reset password
                                        </Button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}