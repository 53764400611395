import './index.css'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../../../utils/ContextProvider.jsx'
import Button from '@mui/material/Button'

export default function FormBodySelection( {heading = null, subheading = null, selectionOptions = null, handleClick = null, partner = null} ) {

    var { partner } = useAppContext()
    
    var buttonSize = ""

    if (selectionOptions.length <= 2) {
        buttonSize = "col-md-5"
    } else if (selectionOptions.length <=3)  {
        buttonSize = "col-md-4"
    } else {
        buttonSize = "col-md-3"
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-auto mx-auto text-center text-sm-start">
                    <h2>{heading}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-auto mx-auto text-center text-sm-start">
                    <p>{subheading}</p>
                </div>
            </div>

            <div className="row">
                {selectionOptions.map((item, index)=> (
                    <div className={`col-10 ${buttonSize} py-2 mt-md-2 mx-auto`} key={item.name + item.value + 'Button'}>
                        <Button
                        className="btn form-card-button py-4 py-md-5 mx-auto text-center" 
                        name={item.name} 
                        value={item.value} 
                        onClick={handleClick} 
                        sx={{
                            color: 'black',
                            border: '1px solid #EEEBEB',
                            textTransform: 'none', // Default and selected border color
                            '&:hover': {
                              borderColor: partner? `${partner.primary} !important` : null,
                              backgroundColor: 'white' // Hover border color depending on selected state
                            },
                          }}
                        >
                             {item.imageUrl && item.imageUrl !== null ? 
                                <div className="col-11 mx-auto pb-4">
                                    <img className='img img-fluid' src={item.imageUrl} alt="" /> 
                                </div>                
                                : null} 
                             {item.title} <br /> 
                             {item.subHeading}
                        </Button>
                    </div>
                ))}
            </div>
        </div> 
    )
}