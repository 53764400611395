import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { useAppContext } from "../../utils/ContextProvider.jsx";
import FlashAlert from "../../components/Alert";
import { Outlet } from "react-router-dom";

export default function WebsitePage() {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl } = useAppContext()

    return (
        <div className="d-flex flex-column vh-100 px-0 py-0">
            <NavBar />
                {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
                {isAlert.success ? <FlashAlert severity={"success"} message={isAlert.success} setAlertState={setAlertState} />: null}
                <Outlet />
            <Footer/>
        </div>
        

    )
}