import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../../utils/ContextProvider.jsx";
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx";
import Rating from '@mui/material/Rating';
import FormControl from "@mui/material/FormControl";
import axios from 'axios'
import InputLabel from "@mui/material/InputLabel";
import MenuItem  from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import  TextField  from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import * as Sentry from "@sentry/react";

export default function SubmitShoeReviewPage () {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, isProcessing, setProcessing, requestUrl } = useAppContext()

    const { recommendationId, shoeName } = useParams()
    const { shoe, preferences, requirments } = useLocation().state
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        rating: 0,
        distanceRan: "0-50km",
        title: "",
        description: ""

    })

    const [formDataError, setFormDataError] = useState({
        rating: false,
        distanceRan: false,
        title: false,
        description: false
    })

    const updateFormData = (evt) => {
        const changedField = evt.target.name
        const newValue = evt.target.value
        setFormData(currData => {
            return {
                ...currData,
                [changedField]: newValue
            }
        })
    }

    var breadcrumb = [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: `${preferences? preferences.shoeUsage : null}, ${preferences? preferences.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        },
        {
            title: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`,
            href: `/dashboard/recommendations/${recommendationId}/${shoeName}`
        },
        {
            title: `Submit review`,
            href: `/dashboard/recommendations/${recommendationId}/${shoeName}/submit-review`
        }

    
    ]

    if (!shoe) {
        navigate(`/dashboard/recommendations/${recommendationId}/${shoeName}`, { state: { shoe: shoe }})
    }

    const handleSubmit = (evt) => {
        const review = formData
        if (review.title === "" || review.description === "") {
            if (review.title === "") {
                setFormDataError(currData => {
                    return {
                        ...currData,
                        title: true
                    }
                })
            }
            if (review.description === "") {
                setFormDataError(currData => {
                    return {
                        ...currData,
                        description: true
                    }
                })
            }
        } else {
            var url = `${requestUrl}/api/reviews/${shoe.shoe._id}`
            setProcessing(true)
            axios({
                url: url,
                method: "POST",
                data: {
                    rating: formData.rating,
                    distanceRan: formData.distanceRan,
                    title: formData.title,
                    description: formData.description
                },
                withCredentials: true
            }           
            )
            .then((res) => {
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        success: `Thanks for submitting a review!`
                    }
                })
                setProcessing(false)
                navigate(`/dashboard/recommendations/${recommendationId}`)
            })
            .catch((err) => {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setProcessing(false)
                navigate(`/dashboard/recommendations/${recommendationId}`)
            })
        }
    }

    return (
        <div>
            <DashboardHeader breadcrumb={breadcrumb} />
            <div className="container-fluid dashboard-body pt-5 pb-5" id="recommedationsArea">
                <div className="container-lg">
                    <div className="row">
                        {/* Shoe review tile */}
                        <div className="col-11 mx-auto show-shoe-tile py-5">
                            <div className="row align-items-center">

                                {/* Shoe and brand image row */}
                                <div className="col-12 col-md-5 mx-auto">
                                    <div className="row">
                                        <div className="col-8 mx-auto text-center">
                                            <img src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoe.brand.slug}.png`} alt="" className="img img-fluid my-3"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <img src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}.png`} alt="" className="img img-fluid"/>
                                        </div>
                                    </div>
                                </div>

                                {/* Form tile */}
                                <div className="col-12 col-md-6 mx-auto">
                                    <div className="row">
                                        <div className="col mt-4">
                                            <form>
                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3>Rating</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                                <Rating
                                                                        name="rating"
                                                                        size="large"
                                                                        value={formData.rating}
                                                                        onChange={updateFormData}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3>Title</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                                <TextField error={formDataError.title === true ? true : null} helperText={formDataError.title === true? "Title required": null} id="titleTextField" label="Title" variant="outlined" name="title" fullWidth value={formData.title} onChange={updateFormData}  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3>Description</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                                <TextField error={formDataError.description === true ? true : null} helperText={formDataError.description === true? "Description required": null} id="descriptionTextField" label="Description" variant="outlined" name="description" multiline  fullWidth value={formData.description} onChange={updateFormData}  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                
                                                <div className="row my-4">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3>Usage</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col">
                                                            <FormControl fullWidth>
                                                                <InputLabel id="distanceRanSelect">Distance</InputLabel>
                                                                <Select
                                                                labelId="distanceRanSelect"
                                                                id="distanceRan"
                                                                value={formData.distanceRan}
                                                                label="distanceRan"
                                                                name="distanceRan"
                                                                onChange={updateFormData}
                                                                >
                                                                    <MenuItem value={"0-50km"}>0-50km</MenuItem>
                                                                    <MenuItem value={"50-100km"}>50-100km</MenuItem>
                                                                    <MenuItem value={"100-150km"}>100-150km</MenuItem>
                                                                    <MenuItem value={"150-200km"}>150-200km</MenuItem>
                                                                    <MenuItem value={">250km"}> More than 250km</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        
                                                        <Button 
                                                            variant="contained"
                                                            onClick={handleSubmit}
                                                            disabled={isProcessing == true ? true : false}
                                                            endIcon={isProcessing == true ? <CircularProgress size={24} sx={{ color: 'white' }} /> : null}
                                                            >
                                                                Submit review
                                                        </Button> 
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}