import NavBar from "../../components/NavBar"
import Footer from "../../components/Footer"
import { useAppContext } from '../../utils/ContextProvider.jsx'
import { useEffect, useState } from "react"
import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom"
import FlashAlert from "../../components/Alert"
import "./index.css"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import axios from "axios"
import * as Sentry from "@sentry/react";

export default function DashboardPage() {

    const location = useLocation()

    const navigate = useNavigate()

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading } = useAppContext()

    var [user, setUser] = useState()

    var [userShoes, setUserShoes] = useState()

    var [fetchingInfo, setFetchingInfo] = useState()
  
    useEffect(()=> {
        var url = `${requestUrl}/api/userShoes`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
        setUserShoes(res.data.userShoes)
        })
        .catch((err)=> {
        Sentry.captureException(err)
        var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
        })

        var user = null

        axios({
            url: `${requestUrl}/api/users`,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            setUser(res.data.user)
            user = res.data.user
            if (!user) {
                localStorage.removeItem('isAuthenticated')
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: 'Please login to view your dashboard'
                    }
                })
                navigate('/auth/login')
            }

        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            navigate('/auth/login')
        })

  
    },[])

    return (
        <div className="d-flex flex-column vh-100 px-0 py-0">
            <NavBar />
                {isAlert.error ? <FlashAlert severity={"error"} message={isAlert.error} setAlertState={setAlertState} />: null}
                {isAlert.success ? <FlashAlert severity={"success"} message={isAlert.success} setAlertState={setAlertState} />: null}
                <Outlet context={[user, userShoes]} />
            <Footer />
            <ScrollRestoration/>
        </div>
    )
    
}