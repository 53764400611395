import DashboardHeader from "../../../components/Dashboard/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useAppContext } from "../../../utils/ContextProvider.jsx";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ChipComponent from "../../../components/Chip";
import TableRow from '@mui/material/TableRow';
import { Tab, Tooltip } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RecommendationToolTip from "../../../components/Dashboard/ToolTip/RecommendationToolTip";
import Modal from "../../../components/Modal";
import RecommendationReasoningModal from "../../../components/Dashboard/Modal/RecommendationReasoningModal";
import Button from "@mui/material/Button";
import FeedbackSnackbar from "../../../components/Snackar/Feedback Snackbar";
import * as Sentry from "@sentry/react";
import { ScrollRestoration } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function CompareShoePage() {

    var { recommendationId } = useParams()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl } = useAppContext()

    const navigate = useNavigate()



    const { shoe } = useLocation().state || {}

    const [recommendation, setRecommendation] = useState({})

    const [shoeComparisonList, setShoeComparisonList] = useState([])

    const [open, setOpen] = useState(false)

    const [openVariable, setOpenVariable] = useState()

    useEffect(() => {
        setLoading(true)
        if (shoe) {
            setShoeComparisonList([shoe, {shoe: {_id: '0'}}, {shoe: {_id: '0'}}, {shoe: {_id: '0'}}])
        } else  {
            setShoeComparisonList([{shoe: {_id: '0'}}, {shoe: {_id: '0'}}, {shoe: {_id: '0'}}, {shoe: {_id: '0'}}])
        }
        var url = `${requestUrl}/api/recommendations/${recommendationId}`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            var response = res.data.recommendation
            setRecommendation(response)
            setLoading(false)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setLoading(false)
            navigate('/dashboard')
        })

    }, [])

    const handleSelectShoe = (evt, index, value) => {
        var index = index
        var shoe = value
        var updatedArray = shoeComparisonList.slice()
        updatedArray[index] = shoe
        setShoeComparisonList(updatedArray)
    }
    

    var breadcrumb = [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: `${recommendation.preferences? recommendation.preferences.shoeUsage : null}, ${recommendation.preferences? recommendation.preferences?.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        },
        {
            title: `compare`
        }
    
    ]

    const openRecommendationModal = (evt, variable) => {
        setOpen(true)
        setOpenVariable(variable)
    }


    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader 
                    heading={'Compare running shoes'}
                    subHeading={'Select a number of running shoes, and compare them side-by-side based on key information and how they fit your recommendation.'}
                    breadcrumb={breadcrumb}
    
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
                        <div className="row mx-4">
                            <div className="col-12 mx-auto show-shoe-tile py-5">
                                <div className="row">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                            <TableCell></TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell align="center" key={shoe?.shoe?._id+'ShoeImageCell'+`${index}`}>
                                                        <Link style={{cursor: shoe?.shoe?.brand ? 'pointer' : 'default'}} to={ shoe?.shoe?.brand ? `/dashboard/recommendations/${recommendationId}/shoes/${shoe?.shoe?.brand?.slug}_${shoe.shoe?.model}_${shoe.shoe?.version}` : null} state={{shoe: shoe, preferences: recommendation.preferences, requirements: recommendation.requirements}}>
                                                            <div className="row">
                                                                <div  className="col-6 mx-auto text-center">
                                                                    <img className="img img-fluid" src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe?.shoe?.brand?.slug}.png`} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-10 mx-auto text-center">
                                                                    <img className="img img-fluid" src={ shoe.shoe.brand ? `https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe?.shoe?.brand?.slug}_${shoe?.shoe?.modelSlug}_${shoe?.shoe?.version}_${shoe?.shoe?.colorways[0]?.colorwayCode}.png` : `/assets/placeholder_shoe.svg`} alt="" />
                                                                </div>
                                                            </div>   
                                                        </Link>
                                                        
                                                    </TableCell>
                                                )
                                            })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >

                                        <TableRow>
                                            <TableCell>Select a shoe</TableCell>
                                        {shoeComparisonList?.map((shoe, index) => {
                                        return (
                                            <TableCell key={`${index}ShoeSelector`} align="center">
                                                <div className="col-12 text-center">
                                                <Autocomplete
                                                        id="country-select-demo"
                                                        options={recommendation.shoes}
                                                        value={shoe.shoe._id == '0' ? null : shoe}
                                                        disableClearable
                                                        autoHighlight
                                                        getOptionLabel={(shoe) => `${shoe?.shoe?.brand?.name} ${shoe?.shoe?.model} ${shoe?.shoe?.version}`}
                                                        onChange={(evt, value) => handleSelectShoe(evt, index, value)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label="Select a shoe"
                                                            autoComplete="false"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'off', // disable autocomplete and autofill
                                                            }}
                                                            /> 
                                                        )}
                                                    />

                                                </div>
                                                
                                            </TableCell>
                                        )
                                    })}
                                        </TableRow>

                                        {/* Suitability */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Suitability</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell 
                                                    align="center"
                                                     sx={{color: parseFloat(shoe.score/shoe.maxScore*100) > 80 ? 'green' : (parseFloat(shoe.score/shoe.maxScore*100)) > 60 ? 'orange' : 'red' }} 
                                                     key={shoe.shoe?._id+'SuitabilityCell'+`${index}`}>
                                                        {shoe.score ? `${parseFloat((shoe.score/shoe.maxScore)*100).toFixed(0)}%` : null} 
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Rating */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Ratings</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell 
                                                    align="center"
                                                     sx={{color: parseFloat(shoe?.shoe.averageWebsiteScore) > 8.5 ? 'green' : (parseFloat(shoe?.shoe.averageWebsiteScore)) > 7.5 ? 'orange' : (parseFloat(shoe?.shoe.averageWebsiteScore)) > 7 ? 'red' : 'grey' }} 
                                                     key={shoe.shoe?._id+'RatingCell'+`${index}`}>
                                                        {shoe?.shoe.averageWebsiteScore && shoe?.shoe.averageWebsiteScore != 'NaN' ? `${(shoe?.shoe.averageWebsiteScore / 2).toFixed(1)}/5 (${shoe?.shoe.websiteReviews?.length})` : '-'}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>



                                        {/* bestFor */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Best for</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell align="center" key={shoe.shoe?._id+'BestForCell'+`${index}`}>
                                                        {shoe.shoe.bestFor ? `${shoe.shoe.bestFor}` : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Key traits */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Key traits</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell align="center" key={shoe.shoe?._id+'traitsCell'+`${index}`}>
                                                        {shoe.shoe.traits ? `${shoe.shoe.traits.join(', ')}` : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>


                                        {/* Surface */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Surface</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell align="center" key={shoe.shoe?._id+'SurfaceCell'+`${index}`}>
                                                        {shoe.shoe.surface ? `${shoe.shoe.surface}` : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Price */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Price</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                return (
                                                    <TableCell align="center" key={shoe.shoe?._id+'PriceCell'+`${index}`}>
                                                        {shoe.shoe.msrp ? `$${shoe.shoe.msrp}` : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        

                                        {/* Cushion */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Cushion</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'cushion'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.cushion.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.cushion.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'CushionCell'+`${index}`}>
                                                        {shoe.shoe.cushion ? `${shoe.shoe.cushion} (${shoe.shoe.stackHeight}mm)` : null} {recommendation.requirements.cushion.reasoning.length > 0 && shoe.shoe.cushion? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'cushion'}/> : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Stability */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Stability</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'stability'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.stability.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.stability.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'StabilityCell'+`${index}`}>
                                                        {shoe.shoe.stability ? `${shoe.shoe.stability}` : null} {recommendation.requirements.stability.reasoning.length > 0 && shoe.shoe.stability? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'stability'}/> : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Weight */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Weight</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'weight'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.weight.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.weight.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'WeightCell'+`${index}`}>
                                                        {shoe.shoe.weight ? `${shoe.shoe.weight}g` : null} {recommendation.requirements.weight.reasoning.length > 0 && shoe.shoe.weight? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'weight'}/> : null}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* HeelToeDrop */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Heel-toe-drop</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'heelToeDrop'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.heelToeDrop.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.heelToeDrop.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'HeelToeDropCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.heelToeDrop ? `${shoe.shoe.heelToeDrop}mm (${parseInt(shoe.shoe?.heelToeDrop) > 8 ? 'high' : (parseInt(shoe.shoe?.heelToeDrop) > 3 ? 'medium' : 'low')})`  : null} {recommendation.requirements.heelToeDrop.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'heelToeDrop'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Shoe shape */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Shoe shape</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'shoeShape'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.shoeShape.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.shoeShape.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'ShoeShapeCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.shoeShape ? `${shoe.shoe.shoeShape}`  : null} { recommendation.requirements.shoeShape.reasoning.length > 0 && shoe.shoe.shoeShape? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'shoeShape'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Width options */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Width options</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'width'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.width.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.width.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'WidthOptionCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.width ? `${shoe.shoe.width.join(',')}`  : null} {recommendation.requirements.width.reasoning.length > 0 && shoe.shoe.width? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'width'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* widthRatingForefoot options */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Runs wide or narrow?</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'widthRatingForefoot'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.widthRatingForefoot.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.width.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'widthRatingForefootOptionCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.widthRatingForefoot ? `${shoe.shoe.widthRatingForefoot}`  : null} {recommendation.requirements.widthRatingForefoot.reasoning.length > 0 && shoe.shoe.widthRatingForefoot? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'widthRatingForefoot'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Grip */}
                                        {recommendation.preferences?.shoeSurface === 'trail' || recommendation.preferences?.shoeSurface === 'hybrid' ?
                                        <TableRow hover={'true'}>
                                            <TableCell>Grip</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'grip'})[0]
                                                return (
                                                    <TableCell 
                                                    align="center" 
                                                    sx={{color: recommendation.requirements.grip.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.grip.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                    key={shoe.shoe?._id+'GripCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.grip ? `${shoe.shoe.grip} (${shoe.shoe.lugDepth}mm lugs)`  : null} {recommendation.requirements.grip.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'grip'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                        : null}

                                        {/* Carbon plate */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Carbon plate</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'carbonPlate'})[0]
                                                return (
                                                    <TableCell 
                                                    align="center" 
                                                    sx={{color: recommendation.requirements.carbonPlate.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.carbonPlate.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                    key={shoe.shoe?._id+'CarbonPlateCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.carbonPlate ? `${shoe.shoe.carbonPlate}`  : null} {recommendation.requirements.carbonPlate.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'carbonPlate'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Rocker */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Rocker</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'rocker'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.rocker.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.rocker.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'CarbonPlateCell'+`${index}`}
                                                        >
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.rocker ? `${shoe.shoe.rocker}`  : null} {recommendation.requirements.rocker.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'rocker'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* Shoe Last */}
                                        <TableRow hover={'true'}>
                                            <TableCell>Shoe Last</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'shoeLast'})[0]
                                                return (
                                                    <TableCell 
                                                        align="center" 
                                                        sx={{color: recommendation.requirements.shoeLast.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.shoeLast.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} 
                                                        key={shoe.shoe?._id+'ShoeLastCell'+`${index}`}
                                                        >
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.shoeLast ? `${shoe.shoe.shoeLast}`  : null} {recommendation.requirements.shoeLast.reasoning.length > 0 && shoe.shoe.heelToeDrop? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'shoeLast'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>

                                        {/* RockPlate */}
                                        {recommendation.preferences?.shoeSurface === 'trail' || recommendation.preferences?.shoeSurface === 'hybrid' ?
                                        <TableRow hover={'true'}>
                                            <TableCell>Rock plate</TableCell>
                                            {shoeComparisonList?.map((shoe, index) => {
                                                var rec = shoe.criteria?.filter((crit) => {return crit.trait === 'rockPlate'})[0]
                                                return (
                                                    <TableCell align="center" sx={{color: recommendation.requirements.rockPlate.reasoning.length > 0 && rec?.pass === 'TRUE' ? 'green' : (recommendation.requirements.rockPlate.reasoning.length > 0 && rec?.pass === 'FALSE' ? 'red' : null)}} key={shoe.shoe?._id+'RockPlateCell'+`${index}`}>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-auto">
                                                                {shoe.shoe.rockPlate ? `${shoe.shoe.rockPlate}`  : null} {recommendation.requirements.rockPlate.reasoning.length > 0 && shoe.shoe.rockPlate ? <RecommendationToolTip openRecommendationModal={openRecommendationModal} recommendation={recommendation} variable={'rockPlate'}/> : null}
                                                            </div>
                                                        </div>    
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                        : null}


                                        </TableBody>
                                       
                                    </Table>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <FeedbackSnackbar/>
                    <RecommendationReasoningModal openState={open} setOpenState={setOpen} recommendation={recommendation} openVariable={openVariable} />
            </div>
        )
    
    }
} 


