import { Form, useNavigate } from "react-router-dom"
import { useState, useEffect, Suspense } from "react"
import axios from "axios"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"
import { useAppContext } from '../../../../utils/ContextProvider.jsx'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from "../../../../components/Loader"
import { preferences } from "joi"
import * as Sentry from "@sentry/react";
import { ScrollRestoration } from "react-router-dom"

export default function PricePage({ progress, section, isAutoComplete} ) {

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, isProcessing, setProcessing, requestUrl, trackEvent, partner } = useAppContext()

    const [user, setUser] = useState({})

    const [lowBudget, setLowBudget] = useState(0);
    const [highBudget, setHighBudget] = useState(550)

    const navigate = useNavigate()

    const handleChange = (evt) => {
        if (evt.target.name === 'highBudget') {
            setHighBudget(evt.target.value)
        } else if (evt.target.name === 'lowBudget') (
            setLowBudget(evt.target.value)
        )
      };

    //   If no running prfile in state, fetch user and set them.
      useEffect(() => {
        if((!localStorage.getItem('runningProfile')) || Object.keys(JSON.parse(localStorage.getItem('runningProfile'))).length < 12 || localStorage.getItem('isAuthenticated')) {
            setLoading(true)
            axios({
                url: `${requestUrl}/api/users?partner=${partner}`,
                method: "GET",
                withCredentials: true
            })
            .then((res) => {
                var user = res.data.user
                setUser(user)
                setLoading(false)
            })
            .catch((err)=> {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: errorMessage
                    }
                })
                setLoading(false)
            })
        }
      }, [])



    const handleSubmit = (evt) => {
        setProcessing(true)
        const budget = {
            lowPrice: lowBudget,
            highPrice: highBudget
        }
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, "shoeBudget": budget}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        var finalPreferencesString = window.localStorage.getItem('preferences')
        var finalPreferencesObject = JSON.parse(finalPreferencesString)

        
        var finalRunningProfileObject = null
        var finalRunningProfileString = window.localStorage.getItem('runningProfile')
        finalRunningProfileObject = JSON.parse(finalRunningProfileString)
        
        if (!finalRunningProfileObject || Object.keys(finalRunningProfileObject).length < 12 || localStorage.getItem('isAuthenticated') ) {
            // If the running profile does not exist in local storage (e.g user has not come from the form), use the user that has been fetched
            finalRunningProfileObject = user.runningProfile
        } else { 
            // Else use what is in lcoal storage
            var finalRunningProfileString = window.localStorage.getItem('runningProfile')
            finalRunningProfileObject = JSON.parse(finalRunningProfileString)
        }
        
        var url = null

        // If from a partner, send a query with the request url to indicate to filter options to certain brands
        if (partner) {
            url = `${requestUrl}/api/recommendations?partner=${partner._id}`
        } else {
            url = `${requestUrl}/api/recommendations`
        }

        axios({
            url: url,
            method: "POST",
            withCredentials: true,
            data: {
                runningProfile: finalRunningProfileObject,
                preferences: finalPreferencesObject
            }
        })
        .then((res) => {

            // Track required event
            trackEvent('Recommendation created', 
            {
                shoeSurface: finalPreferencesObject?.shoeSurface, 
                shoeUsage: finalPreferencesObject?.shoeUsage, 
                shoeBudgetHigh: finalPreferencesObject?.shoeBudget.highPrice, 
                shoeBudgetLow: finalPreferencesObject?.shoeBudget.lowPrice,
                brands: finalPreferencesObject.shoeBrands.length > 0 ? true : false,
                brandsList: finalPreferencesObject.shoeBrands,
                partner: partner?.name || null
            }
            )

            // If not a user, send to final page
            if (!res.data.user) {
                var recommendedShoeString = JSON.stringify(res.data.recommendedShoe)
                var recommendationString = JSON.stringify(res.data.recommendation)
                setProcessing(false)
                var url = null
                if (partner) {
                    url = '/shoe-finder/result'
                } else {
                    url = '/shoe-finder/submit'
                }
                navigate(url, {state: { recommendation: recommendationString, recommendedShoe: recommendedShoeString, alert: res.data.alert }})
            
                // If if a user, redirect to dashboard with new recommendation
            } else {
                var recommendedShoeString = JSON.stringify(res.data.recommendedShoe)
                localStorage.removeItem('runningProfile')
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        success: `Recommendation created, welcome back! 👋 `
                    }
                })
                setProcessing(false)
                navigate('/dashboard')
            }
            
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(isAlert => {
                return {
                    ...isAlert,
                    error: errorMessage
                }
            })
            setProcessing(false)
        })
    }


    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <FormHeader progress={progress} section={section} />
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div> 
        )
    } else {
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <div className="container form-body mt-4">
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <h2>What is your budget range?</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center text-sm-start">
                        <p>Please use the selectors below.</p>
                    </div>
                </div>

                <div className="row mt-3 align-items-center">
                    <div className="col-5 col-md-3">
                    <FormControl fullWidth>
                        <InputLabel id="low-price-label">Low ($)</InputLabel>
                            <Select
                                labelId="low-price-label"
                                id="low-price-select"
                                value={lowBudget}
                                name="lowBudget"
                                label="Lowest"
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>Any</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={150}>150</MenuItem>
                                <MenuItem value={175}>175</MenuItem>
                                <MenuItem value={200}>200</MenuItem>
                                <MenuItem value={225}>225</MenuItem>
                                <MenuItem value={250}>250</MenuItem>
                                <MenuItem value={275}>275</MenuItem>
                                <MenuItem value={300}>300</MenuItem>
                                <MenuItem value={350}>350</MenuItem>
                                <MenuItem value={450}>450</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                            </Select>
                    </FormControl>
                    </div>
                    <div className="col-auto">
                        <div className="row my-auto">
                            <div className="col">
                                <p>---</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-3">
                        <FormControl fullWidth>
                            <InputLabel id="high-price-label">High ($)</InputLabel>
                                <Select
                                    labelId="high-price-label"
                                    id="high-price-select"
                                    name="highBudget"
                                    value={highBudget}
                                    label="Highest"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={550}>Any</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={150}>150</MenuItem>
                                    <MenuItem value={175}>175</MenuItem>
                                    <MenuItem value={200}>200</MenuItem>
                                    <MenuItem value={225}>225</MenuItem>
                                    <MenuItem value={250}>250</MenuItem>
                                    <MenuItem value={275}>275</MenuItem>
                                    <MenuItem value={300}>300</MenuItem>
                                    <MenuItem value={350}>350</MenuItem>
                                    <MenuItem value={450}>450</MenuItem>
                                    <MenuItem value={500}>500</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
                </div>
                
            </div>
            
            <FormFooter isAutoComplete={isAutoComplete} handleSubmit={handleSubmit} isProcessing={isProcessing} setProcessing={setProcessing}  />    
        </div>
                 
    )
}}