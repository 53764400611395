import { useState, useEffect } from "react"
import { Link, ScrollRestoration, redirect, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios"
import FlashAlert from "../../../components/Alert/index.jsx";
import { useAppContext } from '../../../utils/ContextProvider.jsx'
import Loader from "../../../components/Loader/index.jsx";
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx";
import HintCard from "../../../components/Dashboard/Cards/HintCard/index.jsx";
import RecommendationCard from "../../../components/Dashboard/Cards/RecommendationCard/index.jsx";
import { Button } from "@mui/material";
import StartIcon from '@mui/icons-material/Start';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import ShoeLockerSection from "./ShoeLocker/index.jsx";
import * as Sentry from "@sentry/react";

export default function DashboardHomePage() {


    const navigate = useNavigate()

    var buttons = [
        {
            text: 'Create a new recommendation',
            function: () => {return navigate('/shoe-finder/preferences/surface')}
        },
        {
            text: 'Update your running profile',
            function: () => {return navigate('/dashboard/profile')}
        }

    ]

    var [user, setUser] = useState({})

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, requestUrl, trackEvent } = useAppContext()

    const location = useLocation()

    useEffect(() => {
        setLoading(true)
        var url = `${requestUrl}/api/users`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {    
            if (!res.data.user) {
                localStorage.removeItem('isAuthenticated')
                setIsAlert(pageData => {
                    return {
                        ...pageData,
                        error: 'Please login to view your dashboard'
                    }
                })
                navigate('/auth/login')
            } else {
                setUser(res.data.user)
                setLoading(false)
            }
            
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setLoading(false)
            localStorage.removeItem('isAuthenticated')
            navigate('/')
        })
        
        
    }, [])
    


    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <DashboardHeader
                    heading={`Dashboard (👋, ${user?.name})`}
                    subHeading={'Recommendations for running shoes based on your running style, habits and requirements.'}
                    buttons={buttons}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
                    <div className="container-md">
                        <div className="row road-page-header">
                            <div className="col-12 col-lg-10 mx-auto">
                                {/* Heading to body */}
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-11 col-md-9 mx-auto mx-md-0">
                                        <div className="row">
                                            <div className="col text-center text-md-start">
                                                <h2>Recommendations {user?.recommendation ? `(${user.recommendation?.length})` : null}</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center text-md-start">
                                                <p className="mb-2 dashboard-text">Recommendations for running shoes based on your running style, habits and requirements. </p>
                                            </div>
                                        </div>
                                    </div>
                                    {user.recommendation?.length > 2 ?
                                    <div className="col-auto mx-auto mx-md-0 text-center">
                                        <div className="row">
                                            <Link to={'/dashboard/recommendations'} style={{ color: '#212529'}}>View all <ArrowRightAltOutlinedIcon/></Link>
                                        </div>
                                    </div>
                                    : null}
                                    
                                </div>

                                {/* Recommendation cards */}
                                <div className="row my-3">
                                    {user.recommendation?.slice(0,2).map((recommendation, index) => 
                                        <div className="col-12 mx-auto mx-lg-0 col-md-10 col-lg-6 mb-3" style={{height: '100%'}} key={`${recommendation._id}RecommendationCard`}>
                                            <RecommendationCard key={user?.recommendation._id} recommendation={recommendation} />
                                        </div>
                                    )}
                                    {user.recommendation?.length == 0 ? 
                                        <div className="col-auto mx-auto mb-3">
                                            <Button
                                                endIcon={<StartIcon />}
                                                variant="contained"
                                                href="/shoe-finder/intro"
                                                >
                                                Create recommendation
                                            </Button>
                                        </div>
                                    : null}
                                </div>

                                <div className="row justify-content-between align-items-center mt-5">
                                    <div className="col-11 col-md-8 mx-auto mx-md-0">
                                        <div className="row">
                                            <div className="col text-center text-md-start">
                                                <h2>Shoe locker</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center text-md-start">
                                                <p className="mb-2 dashboard-text">A place for information about your current and past running shoes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto mx-auto mx-md-0 text-center">
                                        <div className="row">
                                            <Link to={'/dashboard/shoe-locker'} style={{color: '#212529'}}>Go to shoe locker <ArrowRightAltOutlinedIcon/></Link>
                                        </div>
                                    </div>
                                        
                                </div>

                                {/* Shoe locker section */}
                                <ShoeLockerSection/>

                                <div className="row justify-content-between align-items-center mt-5">
                                    <div className="col-11 col-md-9 mx-auto mx-md-0">
                                        <div className="row">
                                            <div className="col text-center text-md-start">
                                                <h2>Additional resources</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center text-md-start">
                                                <p className="mb-2 dashboard-text">More information including; articles, partners offers and guides to help you on your running journey.</p>
                                            </div>
                                        </div>
                                    </div>          
                                </div>

                                <div className="row my-3">
                                    <div className="col-12 col-sm-6 col-md">
                                        <div className="row">
                                            <div className="col pt-3 pb-4 mx-1 show-shoe-tile">
                                                <div className="row mt-3">
                                                    <div className="col-5 mx-auto text-center">
                                                        <a href="https://www.cmfrunning.nz/" target="_blank" onClick={(evt) => trackEvent('Affiliate view', {partner: 'CMF running', location: 'homepage'})}><img className="img img-fluid" src="/icons/partners/cmf_running.avif" alt=""/></a>
                                                    </div>
                                                </div>
                                                <div className="row mt-3 px-3">
                                                    <div className="col-auto text-center mx-auto px-2">
                                                        <h2>Need help with your training plan?</h2>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-auto text-center mx-auto">
                                                        <p>Book a free consultation with our trusted coaching partner, CMF Running, to help you smash your goals.</p>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-auto text-center mx-auto">
                                                        <Button variant="outlined" size="small" href="https://docs.google.com/forms/d/1RHYRc3vY9keEbBFk2dbewA36Izsbtac_FIsLdWOzvOI/" target="_blank" onClick={(evt) => trackEvent('Affiliate click', {partner: 'CMF running', location: 'homepage'})}>Book a free call</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md">
                                        
                                    </div>
                                    <div className="col-6 col-md">
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}