import Tooltip from "@mui/material/Tooltip";
import ChipComponent from "../../../../components/Chip";
import StarRateIcon from "@mui/icons-material/StarRate";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Fragment, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "../../../../components/Modal";
import { useOutletContext } from "react-router-dom";
import { Button } from "@mui/material";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";

export default function ShoeCardResult({ shoe = null, requirements = null, open = null, setOpen = null }) {

    var [partner] = useOutletContext()

    var { trackEvent } = useAppContext()

    var colorwayIndex = shoe.shoe.colorways?.indexOf(shoe.shoe.colorways.filter((colorway) => colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)))[0]) 

    if (colorwayIndex == -1) {
        colorwayIndex = 0
    }

    var [expanded, setExpanded] = useState(false)

    const handleAccordianClick = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    var [reasoningModalOpen, setReasoningModalOpen] = useState(false)

    var handleClick = (evt) => {
        trackEvent('View options', {shoe: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`, partner: partner.name})
    }

    var content = 
    <Fragment>
        <div className="row mt-1">
            <div className="col-12 text-start">
                <p>Based on your running profile and expected use, this shoe is a <span style={{textDecoration: 'underline'}}>{((shoe?.score/shoe?.maxScore)*100).toFixed(0)}% match</span>. Click the dropdowns in each section to learn more about your matching criteria, and the potential impact of a bad decision.</p>
            </div>
        </div>

        <div className="row mb-3">
            <div className="col">
                {shoe?.criteria?.map((criteria, index) => {
                    var string = criteria.trait.split(/(?=[A-Z])/);
                    var finalString = string.join(' ').toLowerCase()
                    if (requirements[criteria.trait].reasoning.length > 0) {
                        var FinalDescriptionString = ""
                        for (let j = 0; j < requirements[criteria.trait].reasoning.length; j++) {
                            FinalDescriptionString += ` ${requirements[criteria.trait].reasoning[j].impact}`
                        }
                        return (
                            <div key={`${criteria.trait}TraitRow`} className="row align-items-center my-1">
                                <div className="col">
                                    <Accordion style={{boxShadow: 'none', border: criteria.pass === 'FALSE' && criteria.priorityScore > 2 ? '1px solid red' : '1px solid #EEEBEB'}}  expanded={expanded === `panel${index}`} onChange={handleAccordianClick(`panel${index}`)}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    {criteria.pass === 'TRUE' ? <CheckCircleOutlineIcon color="success" /> : (criteria.priorityScore > 2 ? <ErrorOutlineIcon color="error" /> : <WarningAmberIcon color="warning" />)  }
                                                </div>
                                                <div className="col-auto">
                                                    <h3 className="my-0">{`${finalString}`}</h3>
                                                </div>
                                            </div>
                                            
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="row my-1 justify-content-evenly">
                                                <div className="col-auto px-5 py-3 trait-tile">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h4 className="my-0">Recommended:</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            <p className="my-0">{requirements[criteria.trait].traits.length ? requirements[criteria.trait].traits.reduce((accu, elem) => {return accu === null ? [elem] : [...accu, ' or ', elem]} ) : Object.entries(requirements[criteria.trait].traits).map((elem) => `${elem[0]}: ${elem[1]}`).join(', ')}</p>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="col-auto px-5 py-3 trait-tile">
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            <h4 className="my-0">This shoe:</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col text-center">
                                                            <p className="my-0">{Array.isArray(criteria.value) ? criteria.value.join(', ') : criteria.value}</p>
                                                        </div>
                                                    </div>           
                                                </div>
                                            </div>

                                            <div className="row pt-3 px-3">
                                                <div className="col text-start">   
                                                    <h4 className="mb-2">Your needs:</h4>
                                                    {requirements[criteria.trait].reasoning.map((reason, index) => { return <p key={`${index}ReasonParagraph`} className="my-2">- {reason.description} </p> })}    
                                                </div>
                                            </div>

                                            <div className="row pt-3 px-3">
                                                <div className="col text-start">   
                                                    <h4 className="mb-2">Impact:</h4>
                                                    {requirements[criteria.trait].reasoning.map((reason, index) => { return <p key={`${index}ImpactParagraph`} className="my-2">- {reason.impact} </p> })}    
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        )}})}
                    </div>
        </div>
    </Fragment>
    

    return (
        <div className="col-10 col-md-4 mx-auto">
            <div className="row">
                <div className="col grey-outline mx-2 my-3">
                    <div key={`${shoe.shoe._id}_match_pil`} className="row pt-3 mt-1 mb-3">
                        <div className="col-auto mx-auto">
                            <Tooltip 
                                arrow 
                                disableFocusListener 
                                enterTouchDelay={0} 
                                leaveTouchDelay={5000}
                                title={
                                <span>
                                    {shoe.criteria.map((criteria) => {
                                        var string = criteria.trait.split(/(?=[A-Z])/);
                                        var finalString = string.join(' ').toLowerCase()
                                        if (requirements[criteria.trait].reasoning.length > 0) {
                                            return (
                                                <div key={`${criteria.trait}TraitRow`} className="row">
                                                    <div className="col">
                                                        {criteria.pass == 'TRUE' ? `✅ ${finalString}` : `🚨 ${finalString}`}: {Array.isArray(shoe.shoe[criteria.trait]) ? shoe.shoe[criteria.trait].join(', ') : shoe.shoe[criteria.trait]}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <a onClick={(evt) => setReasoningModalOpen(true)} style={{textDecoration: 'underline', cursor: 'pointer'}}>Learn more</a>
                                </span>
                                }
                            >
                                <span>
                                    <ChipComponent
                                        icon={
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                            
                                        }
                                        label={`${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match`}
                                        color={
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                            
                                        }
                                        variant={(shoe.score / shoe.maxScore) < 1.0 ? 'outlined' : null}
                                    />
                                </span>
                            </Tooltip>
                        
                        </div>
                    </div>


                    {/* Brand image */}
                    <div key={`${shoe.shoe._id}_brandImage=`} className="row">
                        <div className="col-9 mx-auto text-center">
                            <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoe.brand.slug}.png`} alt="" />
                        </div>
                    </div>


                    {/* Shoe image */}
                    <div className="row pt-2">
                        <div className="col-12 mx-auto text-center">
                            <img  loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${shoe.shoe.colorways[colorwayIndex].colorwayCode}.png`} alt="" />
                        </div>
                    </div>

                    {/* Shoe name */}
                    <div className="row mt-3">
                        <div className="col-12 mx-auto text-center">
                            <h2>{shoe.shoe.brand.name} {shoe.shoe.model} {shoe.shoe.version} {shoe.shoe.width.some(width => requirements.width.traits.includes(width))? `(${requirements.width.traits})` : null}</h2>
                        </div>
                    </div>

                    {/* Shoe description */}
                    <div className="row mt-2 mb-3 justify-content-center">
                        <div className="col-auto px-1 my-1">
                            <ChipComponent
                                        label={`$${shoe.shoe.msrp}`}
                                        size='small'
                                        color='default'
                                    />
                        </div>
                        <div className="col-auto px-1 my-1">
                            <ChipComponent
                                    label={`${shoe.shoe.cushion} cushion (${shoe.shoe.stackHeight}mm)`}
                                    size='small'
                                    color='default'
                                />
                        </div>
                        <div className="col-auto px-1 my-1">
                            <ChipComponent
                                    label={`${shoe.shoe.stability} stability`}
                                    size='small'
                                    color='default'
                                />
                        </div>
  
                    </div>
                    <div className="row mt-3 mb-3 justify-content-center">
                        <div className="col-auto px-1 my-1">
                            <Button 
                                href="https://google.com" 
                                target="_blank" 
                                variant="contained"
                                style={{backgroundColor: partner.primary || null}}
                                >See options</Button>
                        </div>
                        <div className="col-auto px-1 my-1">
                            <Button 
                                onClick={(evt) => setReasoningModalOpen(true)} 
                                variant="outlined"
                                style={{borderColor: partner.primary || null, color: partner.primary || null}}
                                >View reasoning</Button>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            <Modal heading={`Your matching information for ${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`} content={content} openState={reasoningModalOpen} setOpenState={setReasoningModalOpen}/>
        </div>
    )
}