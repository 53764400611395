import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from "react";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import { Button } from "@mui/material";

export default function RecommendationReasoningPanel ({ requirements = null, setOpenState = null }) {

    const { trackEvent } = useAppContext()

    const handleClick = (evt) => {
        trackEvent('View recommendation reasoning', {route: 'shoeListPage'})
        setOpenState(true)
    }

    return (
        <div className="row mt-2 align-items-center justify-content-between py-3 px-1" style={{border: '1px solid green', borderRadius: '0.5rem', background: 'white'}}>
            <div className="col">
                <div className="row align-items-center">
                    <div className="col-10 col-sm-auto mx-auto text-center text-sm-start mx-sm-0">
                        <InfoIcon/>
                    </div>
                    <div className="col-10 col-sm-auto col-md-8 px-1 mx-auto text-center text-sm-start mx-sm-0">
                        <h4 className="my-0">Recommendation reasoning</h4>
                    </div>
                </div>
            </div>
            
            <div className="col-12 col-sm-auto text-center text-sm-start mx-sm-0">
                <Button
                    onClick={handleClick}
                    >
                    Learn more
                </Button>
            </div>
        </div>
        // <Accordion className="py-1" style={{ boxShadow: "none", border: '1px solid #2e7d32' }}>
        //     <AccordionSummary
        //     expandIcon={<ExpandMoreIcon />}
        //     aria-controls="panel1-content"
        //     id="panel1-header"
        //     >
        //         <div className="row align-items-center">
        //             <div className="col-auto">
        //                 <InfoIcon/>
        //             </div>
        //             <div className="col-auto col-md-8 px-1">
        //                 <h4 className="my-0">Learn more about your recommendation</h4>
        //             </div>
        //             <div className="col-auto">
        //                 <Button
        //                     onClick={handleClick}
        //                     >
        //                     Learn more
        //                 </Button>
        //             </div>
        //         </div>
            
        //     </AccordionSummary>
        //     <AccordionDetails>
        //         <div className="row">
        //             <div className="col-auto">
        //                 <p className="">Fittir has created the following list of requirements based on your running profile and intended-use:</p>
        //             </div>
        //         </div> 
        //         {requirements? Object.entries(requirements).map((key, value) => {
        //             var string = key[0].split(/(?=[A-Z])/);
        //             var finalTitleString = string.join(' ').toLowerCase()
        //             if (key[1].reasoning && key[1].reasoning.length > 0) {
        //                 var FinalDescriptionString = ""
        //                 for (let j = 0; j < key[1].reasoning.length; j++) {
        //                     FinalDescriptionString += `${key[1].reasoning[j].description}. `
        //                 }
        //                 return (
        //                     <div className="row">
        //                             <div className="col">
        //                                 <p>{finalTitleString}: {FinalDescriptionString} </p>
        //                             </div>
        //                         </div>
        //                 )
        //             }     
        //         }) : null}
        //     </AccordionDetails>
        // </Accordion>
    )
}