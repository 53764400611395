import { Avatar, Chip } from "@mui/material"
import ChipComponent from "../../../Chip"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import { useOutletContext } from "react-router-dom"

export default function ReviewCard ({ review = null }) {

    var [user, userShoes] = useOutletContext()

    return (
        <div className="row review-card pt-3 my-2">
            <div className="col">
                <div className="row">
                    <div className="px-2 d-none d-md-inline col-1">
                        <Avatar />
                    </div>
                    <div className="col-10">
                        <div className="row align-items-center">
                            <div className="col-auto text-start">
                                <h4 className="my-0">{review.userId.name}</h4>
                            </div>
                            { review.userId.runningProfile.gender === user.runningProfile.gender && review.userId.runningProfile.footStrike === user.runningProfile.footStrike && review.userId.runningProfile.footWidth === user.runningProfile.footWidth && review.userId.runningProfile.pronation === user.runningProfile.pronation ?
                            <div className="col-auto px-1">
                                <ChipComponent 
                                    icon={<ThumbUpOffAltIcon />}
                                    variant={'outlined'}
                                    color="success"
                                    size="small"
                                    label={"Similar running profile"}
                                />
                            </div>
                            : null}
                        </div>

                        <div className="row mt-2">
                            <div className="col text-start">
                                <p><i>{review.userId.runningProfile.gender}, {review.userId.runningProfile.pronation} pronation, {review.userId.runningProfile.footWidth} foot, {review.userId.runningProfile.archType} arch, {review.userId.runningProfile.footStrike}  </i></p>
                            </div>
                        </div>
                        
                        
                        <div className="row mt-2">
                            <div className="col text-start">
                                <p>{review.description}</p>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col text-start">
                                <p><i>Used for: {review.usage}</i></p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-1 text-end">
                        <h4 className="my-0 pe-4">{review.rating}/5</h4>
                    </div>
                </div>       
            </div>
        </div>
    )
} 