import { useEffect, useState } from "react"
import axios from "axios"
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import InfoIcon from '@mui/icons-material/Info';
import ReviewCard from "../../../../components/Dashboard/Cards/ReviewCard";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import * as Sentry from "@sentry/react";

export default function ReviewsList( { user = null, shoeId = null, setRunnerReviews = null, averageReviewScore = null, setAverageReviewScore = null }) {

    const { isAlert, setAlertState, setIsAlert, setLoading, requestUrl, trackEvent } = useAppContext()

    var [reviews, setReviews] = useState([])

    var [isLoading, setIsLoading] = useState(false)

    useEffect(()=> {
        if (shoeId) {
            trackEvent('View shoe ratings page')
            setIsLoading(true)
            var url = `${requestUrl}/api/shoeReviews/${shoeId}`
            axios({
                url: url,
                withCredentials: true
            }).then((res) => {
                var reviews = res.data.reviews.filter((review) => review.userId._id !== user._id)
                setReviews(reviews)
                setRunnerReviews(reviews.length)
                if (reviews.length > 1) {
                    setAverageReviewScore((reviews?.reduce((sum, currentValue) => sum + currentValue.rating, 0) / reviews?.length).toFixed(1))
                } else {
                    setAverageReviewScore(reviews[0]?.rating)
                }
                setIsLoading(false)
            }
            ).catch((err)=> {
                Sentry.captureException(err)
                var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
                setIsAlert(isAlert => {
                    return {
                        ...isAlert,
                        error: errorMessage
                    }
                })
                setIsLoading(false)
            })
        }
    }, [shoeId])

    if (isLoading) {
        return (
            <div className="my-4">
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Skeleton animation="wave" />
                    </div>
                </div>
                
            </div>
        )
    } else {
        return (
            <div>
                <div className="row mt-3">
                    <div className="col text-start">
                        <h4>Runner reviews {reviews.length > 0 ? `(${(reviews?.reduce((sum, currentValue) => sum + currentValue.rating, 0) / reviews?.length).toFixed(1)}/5)` : null}</h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        {reviews.length > 0 ? reviews?.map((review, index) => {
                            return (
                                <div className="row">
                                    <div className="col">
                                        <ReviewCard review={review} />
                                    </div>
                                </div>
                            )
                        }) 
                        : 
                        <div className="row">
                            <div className="col text-start">
                            <p><i>We couldn't find any reviews from other runners on Fittir who own this shoe.</i></p>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                
            </div>
            
        )
    }
    
}