import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "Light trails",
        subHeading: "e.g gravel roads, walking trails",
        name: "trailSurface",
        value: "light"

    },
    {
        title: "Moderate trails",
        subHeading: "e.g maintained single track, mountain-bike trails ",
        name: "trailSurface",
        value: "moderate"

    },
    {
        title: "Technical trails",
        subHeading: "e.g muddy trails, back-country, mountain ridge lines ",
        name: "trailSurface",
        value: "technical"

    }
]

export default function TrailSurfacePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, "trailSurface": evt.target.value}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        if (evt.target.value === "moderate" || evt.target.value === "technical") {
            navigate('/shoe-finder/preferences/surface/rock_plate')
        } else {
            navigate('/shoe-finder/preferences/usage')
        }  
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"What kind of trial will you be mostly running on?"} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
                toolTip={"This helps us understand what sort of grip, and height you'll need based on how technical the terrain is."}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}