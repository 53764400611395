import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../../components/Form/FormHeader"
import FormFooter from "../../../../../components/Form/FormFooter"
import FormBody from "../../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "The heel/the rear of my foot",
        subHeading: "(heel strike)",
        name: "footStrike",
        value: "heel strike",
        imageUrl: "/formAssets/footstrike/heel.png"

    },
    {
        title: "The front of my foot, I run on my toes",
        subHeading: "(forefoot strike)",
        name: "footStrike",
        value: "forefoot strike",
        imageUrl: "/formAssets/footstrike/forefoot.png"

    },
    {
        title: "The middle of my foot, my foot lands evenly on the ground",
        subHeading: "(midfoot strike)",
        name: "footStrike",
        value: "midfoot strike",
        imageUrl: "/formAssets/footstrike/midfoot.png"

    }
]

export default function FootStrikeUnknownPage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var name = ""
        var value = ""
        if (!evt.target.value) {
            if (evt.target.parentNode.value) {
                value = evt.target.parentNode.value
                name = evt.target.parentNode.name
            } else {
                value = evt.target.parentNode.parentNode.value
                name = evt.target.parentNode.parentNode.name
            }
            
        } else {
            value = evt.target.value
            name = evt.target.name
        }
        var runningProfileString = localStorage.getItem("runningProfile")
        var runningProfileObject = JSON.parse(runningProfileString)
        var newRunningProfileObject = {...runningProfileObject, [name]: value}
        var newRunningProfileObjectString = JSON.stringify(newRunningProfileObject)
        localStorage.setItem("runningProfile", newRunningProfileObjectString)
        navigate('/shoe-finder/running-profile/pronation')
    }
    
    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"When I run, the first part of my foot that hits the ground is...."} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}