import Button from '@mui/material/Button'
import DropdownButton from "./AccountDropdown/index"
import "./index.css"
import { useNavigate } from 'react-router-dom'

export default function NavBar() {

    var navigate = useNavigate()

    return (
            <nav className="navbar sticky-top navbar-expand-lg" style={{backgroundColor: 'white', borderBottom: '1px solid #F8F9FA'}}>
                <div className="container-fluid">
                    <a className="navbar-brand px-3" href="/"><img className="img img-fluid" src="/logos/logo-primary.svg" alt="" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse my-3 my-lg-0 mx-3 mx-lg-0" id="navbarNav">
                        <ul className="navbar-nav">
                            <div className="row">
                                {/* <div className="col-10 col-lg-auto mx-1">
                                    <Button href="/browse-shoes" size='large'>Browse shoes</Button>
                                </div> */}
                                <div className="col-10 col-lg-auto mx-1">
                                    <Button onClick={(evt) => navigate('/shoe-finder/intro')} size='large'>Shoe finder</Button>
                                </div>
                                {/* <div className="col-10 col-lg-auto mx-1">
                                    <Button onClick={(evt) => navigate('/browse-shoes')} size='large'>Browse shoes</Button>
                                </div> */}
                                <div className="col-10 col-lg-auto mx-1">
                                    <Button onClick={(evt) => navigate('/about')} size='large'>About</Button>
                                </div>
                            </div>
                            
                        </ul>
                        {!window.localStorage.isAuthenticated ?
                        <ul className="navbar-nav ms-auto">
                            <div className="row">
                                <div className="col-10 col-lg-auto mx-1">
                                    <Button href="/auth/login" size='large'>Login</Button>
                                </div>
                                <div className="col-10 col-lg-auto mx-1">
                                <Button variant='contained' href="/shoe-finder/intro" size='large'>Get started</Button>
                                </div>
                            </div>
                            
                            
                        </ul>
                        : 
                        <ul className="navbar-nav ms-auto" >
                            <div className="row">
                                {/* <div className='col-10 col-lg-auto mx-1'>
                                    <Button size='large' href='donate' color='primary' variant='outlined'>Donate 🙏</Button>
                                </div> */}
                                <div className='col-10 col-lg-auto mx-1'>
                                    <DropdownButton/>
                                </div>
                            </div>
                            
                        </ul>
                        }
                    </div>
                </div>
            </nav>
    )
}