import { Form, useNavigate } from "react-router-dom"
import FormHeader from "../../../../components/Form/FormHeader"
import FormFooter from "../../../../components/Form/FormFooter"
import FormBody from "../../../../components/Form/FormBody"

var selectionOptions = [
    {
        title: "A bit of everything..",
        subHeading: "(everyday shoe)",
        name: "shoeUsage",
        value: "everyday"

    },
    {
        title: "Long training runs & events",
        subHeading: "(long distance shoe)",
        name: "shoeUsage",
        value: "long_distance"

    },
    {
        title: "Speed work & racing",
        subHeading: "(speed shoe)",
        name: "shoeUsage",
        value: "speed"

    }
]

export default function UsagePage({ progress, section, isAutoComplete} ) {
    
    const navigate = useNavigate()

    const handleClick = (evt) => {
        var preferencesString = localStorage.getItem("preferences")
        var preferencesObject = JSON.parse(preferencesString)
        var newPreferencesObject = {...preferencesObject, [evt.target.name]: evt.target.value}
        var newPreferencesObjectString = JSON.stringify(newPreferencesObject)
        localStorage.setItem("preferences", newPreferencesObjectString)
        if (evt.target.value === 'speed') {
            navigate('/shoe-finder/preferences/speed_type')
        } else {
            navigate('/shoe-finder/preferences/brand')
        }
        
    }

    return (
        <div className="container-lg py-5 main-form-body">
            <FormHeader progress={progress} section={section} />
            <FormBody 
                heading={"I'm looking for a shoe that is best suited to.."} 
                subHeading={"Please select one."}
                formBodyType={"Selection"}
                selectionOptions={selectionOptions}
                isAutoComplete={isAutoComplete}
                handleClick={handleClick}
            />
            <FormFooter isAutoComplete={isAutoComplete}  />    
        </div>
                 
    )
}