import { useState, useEffect, Suspense, Children, useRef } from "react"
import { ScrollRestoration, redirect, useParams, useOutletContext } from "react-router-dom";
import axios from "axios"
import { useAppContext } from '../../../utils/ContextProvider.jsx'
import Loader from "../../../components/Loader/index.jsx";
import DashboardHeader from "../../../components/Dashboard/Header/index.jsx";
import HintCard from "../../../components/Dashboard/Cards/HintCard/index.jsx";
import ShoeCard from "../../../components/Dashboard/Cards/ShoeCard/index.jsx";
import { Button, Divider, Icon, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SortButton from "../../../components/Dashboard/Buttons/SortButton/index.jsx";
import FilterPanel from "../../../components/Dashboard/Panels/FilterPanel/index.jsx";
import CircularProgress from '@mui/material/CircularProgress';
import SearchPanel from "../../../components/Dashboard/Panels/SearchPanel/index.jsx";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import RecommendationReasoningModal from "../../../components/Dashboard/Modal/RecommendationReasoningModal/index.jsx";
import RecommendationReasoningPanel from "../../../components/Dashboard/Panels/RecommendationReasoningPanel/index.jsx"
import {sortingMethodsRecommendation } from "../../../utils/SortingMethods/index.js";
import Alert from "@mui/material/Alert";
import InfoOutlined from "@mui/icons-material/InfoOutlined.js";
import * as Sentry from "@sentry/react";



export default function RecommendationPage({ user = null, userShoes = null }) {

    var [ user, userShoes ] = useOutletContext()

    const { isLoading, isAlert, setAlertState, setIsAlert, setLoading, trackEvent, requestUrl, isProcessing, setProcessing } = useAppContext()
    
    const [recommendation, setRecommendation] = useState({})
    const [shoes, setShoes] = useState([])
    const [filters, setFilters] = useState({
        brands: [],
        price: {
            lowPrice: 0,
            highPrice: 550
        },
        recommendScore: { 
            minScore: 0
        },
        allShoes: true
    })
    const [sort, setSort] = useState('recommended')
    const [search, setSearch] = useState()
    const [page, setPage] = useState(1)
    const [shoeCount, setShoeCount] = useState();
    const [open, setOpen] = useState()
    const parentRef = useRef(null)

    const [alert, setAlert] = useState(false)

    const { recommendationId } = useParams()

    var breadcrumb = [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: `${recommendation.preferences? recommendation.preferences.shoeUsage : null}, ${recommendation.preferences? recommendation.preferences.shoeSurface : null} shoes`,
            href: `/dashboard/recommendations/${recommendationId}`
        }
    
    ]
    

    useEffect(() => {
        setLoading(true)
        var url = `${requestUrl}/api/recommendations/${recommendationId}`
        axios({
            url: url,
            method: "GET",
            withCredentials: true
        })
        .then((res) => {
            var response = res.data.recommendation
            setRecommendation(response)
            setFilters(filters => {
                return {
                    ...filters,
                    brands: response.preferences.shoeBrands,
                    price: {
                        lowPrice: response.preferences.shoeBudget.lowPrice,
                        highPrice: response.preferences.shoeBudget.highPrice
                    }
                }
            })
            var filteredShoes = response.shoes
            .filter((shoe) => {return (response.preferences.shoeBrands.length > 0 ? response.preferences.shoeBrands.includes(shoe.shoe.brand.name) : shoe.shoe.brand.name) && parseInt(shoe.shoe.msrp) <= response.preferences.shoeBudget.highPrice})
            setShoes(filteredShoes)  
            setSearch(null)  
            setLoading(false)
        })
        .catch((err)=> {
            Sentry.captureException(err)
            var errorMessage = err.response?.data.err.message || "Whoops, something wen't wrong"
            setIsAlert(pageData => {
                return {
                    ...pageData,
                    error: errorMessage
                }
            })
            setLoading(false)
            redirect('/dashboard')
        })

    }, [])
    

    const handlePageChange = (evt, value) => {
        setPage(value)
        const element = document.getElementById("sortButton");
        trackEvent('Change page')
        setTimeout(function() {
           element.scrollIntoView()
        },1)
    }

    const handleSearch = (evt) => { 
        if (search && search !== '') {
            var filteredShoes = recommendation.shoes?.filter((shoe) => 
            (shoe.shoe.brand.name + shoe.shoe.model).replace(/\s/g, '').replace('-','').toLowerCase().includes(search.replace(/\s/g, '').replace('-','').toLowerCase())
            )
            setShoes(filteredShoes)
            setAllShoesFilterState(evt, false)
            trackEvent('Search shoes', {searchValue: search})
        }
        
    }

    const clearSearch = (evt) => {
        setSearch('')
        var filteredShoes = recommendation.shoes.filter((shoe) => {return (filters.brands.length > 0 ? filters.brands.includes(shoe.shoe.brand.name) : shoe.shoe.brand.name) && (parseInt(shoe.shoe.msrp) <= filters.price.highPrice && parseInt(shoe.shoe.msrp) >= filters.price.lowPrice) && parseInt(shoe.score/shoe.maxScore * 100) >= filters.recommendScore.minScore})
        setAllShoesFilterState(evt, true)
        setShoes(filteredShoes)
    }

    const handleChange = (evt) => {
        setSearch(evt.target.value)
    }

    const setBudgetFilterState = (evt) => {
        var shoeBudget = filters.price
        setFilters(filters => {
            return {
                ...filters,
                price: {
                    ...filters.price,
                    [evt.target.name]: evt.target.value
                }
            }
        }) 
        if (evt.target.name === 'highPrice') {
            shoeBudget.highPrice = evt.target.value
        } else if (evt.target.name === 'lowPrice') {
            shoeBudget.lowPrice = evt.target.value
        }
        trackEvent('Filter shoes', {filterType: 'budget', filterField: evt.target.name, filterValue: evt.target.value})
        var filteredShoes = recommendation.shoes.filter((shoe) => {return (filters.brands.length > 0 ? filters.brands.includes(shoe.shoe.brand.name) : shoe.shoe.brand.name) && (parseInt(shoe.shoe.msrp) <= shoeBudget.highPrice && parseInt(shoe.shoe.msrp) >= shoeBudget.lowPrice) && parseInt(shoe.score/shoe.maxScore * 100) >= filters.recommendScore.minScore })
        setShoes(filteredShoes)  
        setPage(1)
    }

    const setBrandsFilterState = (evt) => {
        var brand = evt.target.value
        var brandArray = filters.brands
        if (brandArray.includes(brand)) {
            var index = brandArray.indexOf(brand)
            brandArray.splice(index, 1)
            setFilters(filters => {
                return {
                    ...filters,
                    brands: brandArray
                }
            })
            
        } else {
            brandArray.push(brand)
            setFilters(filters => {
                return {
                    ...filters,
                    brands: brandArray
                }
            }) 
            
        }
        trackEvent('Filter shoes', {filterType: 'brand', filterField: 'shoeBrand', filterValue: evt.target.value})
        var filteredShoes = recommendation.shoes.filter((shoe) => {return (brandArray.length > 0 ? brandArray.includes(shoe.shoe.brand.name) : shoe.shoe.brand.name) && parseInt(shoe.shoe.msrp) <= filters.price.highPrice && parseInt(shoe.score/shoe.maxScore * 100) >= filters.recommendScore.minScore})
        setShoes(filteredShoes)  
        setPage(1)
       
    }


    const setRecommendedScoreFilterState = (evt) => {
        var score = evt.target.value
        setFilters(filters => {
            return {
                ...filters,
                recommendScore: {
                    ...filters.recommendScore,
                    minScore: score
                }
            }
        }) 
        trackEvent('Filter shoes', {filterType: 'score', filterField: 'minScore', filterValue: score})
        var filteredShoes = recommendation.shoes.filter((shoe) => {return (filters.brands.length > 0 ? filters.brands.includes(shoe.shoe.brand.name) : shoe.shoe.brand.name) && parseInt(shoe.shoe.msrp) <= filters.price.highPrice && parseInt(shoe.score/shoe.maxScore * 100) >= score})
        setShoes(filteredShoes)
        setPage(1)
        
    }

    const setAllShoesFilterState = (evt, value) => {
        setFilters(filters => {
            return {
                ...filters,
                allShoes: value
            }
        }) 
    }

    const clearFilters = (evt) => {
        trackEvent('Clear filters')
        setFilters(filters => {
            return {
                ...filters,
                brands: [],
                price: {
                    ...filters.price,
                    highPrice: 550,
                    lowPrice: 0
                },
                recommendScore: {
                    ...filters.recommendScore,
                    minScore: 0
                }
            }
        })
        setAlert(false)
        var filteredShoes = recommendation.shoes.filter((shoe) => {return (shoe.shoe.brand.name) && parseInt(shoe.shoe.msrp) <= 550} )
        setShoes(filteredShoes)  
        setPage(1)
    }



    if (isLoading) {
        return (
            <div>
                <Loader />
                    <div className="container-lg main-form-body">
                    
                    <div className="row mt-4"></div>
                    <div className="row" style={{minHeight: '200px'}}>
                    </div>
                    <ScrollRestoration/>
                </div>
            </div>
        )
    } else {

    return (
            <div>
            <DashboardHeader
            heading={`${recommendation.preferences? (recommendation.preferences.shoeUsage).charAt(0).toUpperCase() + recommendation.preferences.shoeUsage.slice(1) : null}, ${recommendation.preferences? recommendation.preferences.shoeSurface : null} running shoes `}
            subHeading={'Based on your running profile and intended use, this contains a ranked list of running shoes that match your needs and preferences.'}
            breadcrumb={breadcrumb}
            />
            <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
                <div className="container-lg">
                    <div className="row">
                        {/* Filters */}
                        <div className="col-11 mx-auto mx-lg-0 col-lg-3">
                            <FilterPanel filters={filters} setFilters={setFilters} shoes={shoes} setShoes={setShoes} setBudgetFilterState={setBudgetFilterState} setBrandsFilterState={setBrandsFilterState} clearFilters={clearFilters} setRecommendScoreFilterState={setRecommendedScoreFilterState} setAllShoesFilterState={setAllShoesFilterState}/>
                            <div className="row mt-2">
                                <div className="col-11 mx-auto px-0">
                                    <RecommendationReasoningPanel requirements={recommendation?.requirements} setOpenState={setOpen} />
                                </div>
                            </div>
                        </div>
                        
                        {/* List */}
                        <div className="col ps-lg-5 shoe-list-column mb-2">
    
                            {/* Shoe results */}
                            { shoes[0]?.score / shoes[0]?.maxScore < 1.0 && ((filters.brands && filters.brands.length > 0) || filters.price.highPrice !== 550 || filters.price.lowPrice !== 0 || filters.recommendScore.minScore !== 0) ?
                                <div className="row align-items-center mt-3 mt-lg-0 mb-3">
                                    <div className="col">
                                        <Alert color="warning" icon={<InfoOutlined/>}>
                                            There are more suitable shoe options outside of your brand and price preferences. <a onClick={clearFilters} style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear your current filters</a> to view these shoes.
                                        </Alert>
                                    </div>
                                </div>
                            : null}

                            <div className="row mt-3 mt-lg-0 ps-lg-2 align-items-center justify-content-center">
                                {/* Search and results number */}
                                <div className="col-10 mx-auto col-md mx-md-0 ">
                                    <div className="row align-items-center">
                                        <div className="col-11 col-md-7 px-0 mx-auto mx-md-0 my-2 my-md-0 search-input">
                                            <SearchPanel search={search} handleChange={handleChange} handleSearch={handleSearch} />
                                        </div>
                                        {search ?
                                        <div className="col-auto">
                                            <a onClick={clearSearch}><p className="my-0" style={{ textDecoration: 'underline', cursor: 'pointer', }}>Clear</p></a>
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                {/* Sorting and compare shoes option */}
                                <div className="col-auto mx-auto">
                                    <div className="row align-items-center justify-content-center justify-content-md-end">
                                        <div className="d-none d-md-inline col-auto">
                                            <Button
                                                variant="outlined" 
                                                endIcon={<CompareArrowsIcon />}
                                                href={`/dashboard/recommendations/${recommendationId}/compare`}
                                                > 
                                                Compare
                                            </Button>
                                        </div>
                                        <div className="d-inline d-md-none col-auto my-2">              
                                                <Tooltip 
                                                    enterTouchDelay={0}
                                                    arrow 
                                                    title={'Give this a go later on a larger device. Trust us, you wont have the best time on your phone.'}
                                                    >
                                                    <span>
                                                        <Button
                                                            disabled    
                                                            variant="outlined" 
                                                            endIcon={<CompareArrowsIcon />}
                                                            href={`/dashboard/recommendations/${recommendationId}/compare`}
                                                            > 
                                                            Compare
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                        
                                            
                                        </div>
                                        <div className="col-auto" id="sortButton">
                                            <SortButton sort={sort} setSort={setSort} />
                                        </div>
                                    </div>
                                </div>                             
                            </div>

                            {(recommendation?.shoes && shoes?.length === 0)  ?
                                <div className="row my-4">
                                    <div className="col-auto mx-auto">
                                       <p>No results. Try clearing your <a style={{textDecoration: 'underline' ,cursor: 'pointer'}} onClick={clearFilters}>preferences</a></p> 
                                    </div>
                                </div>
                            
                            : null}
   
                            
                            

                            <div className="row mt-3" ref={parentRef} id="shoeList">
                                {/* Filter out discontinued shoes, those that aren't the latest model. And those rated poorly by the user. */}
                                {shoes?.filter((shoe, index) => shoe.shoe.discontinued === 'FALSE' && shoe.shoe.latestModel === "TRUE" && (filters.allShoes ? ((userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0]?.shoeReviewId?.rating) > 3 || !userShoes?.filter((userShoe) => userShoe.shoeId._id == shoe.shoe._id)[0]): (shoe))).sort(sortingMethodsRecommendation[sort].method).slice((page - 1) * 6, page * 6).map((shoe, index) => {
                                    const currentShoe = userShoes?.some(userShoe =>
                                        userShoe.shoeId._id.includes(shoe.shoe._id)
                                    )
                                    return (
                                        <div key={`${shoe.shoe._id}`} className="col-12 col-md-6 my-2">
                                            <ShoeCard key={`${shoe.shoe._id}`} shoe={shoe} recommendationId={recommendationId} requirements={recommendation.requirements} preferences={recommendation.preferences} currentShoe={currentShoe} />
                                        </div>    
                                    )
                                })}
                            </div>


                            {Math.ceil(shoes?.length/6) > 0 ?
                            <div className="row mt-4">
                                <div className="col-auto mx-auto">
                                    <Stack spacing={2}>
                                        <Pagination 
                                            key={`page${page}Shoes`}
                                            count={Math.ceil(shoes?.length/6)} 
                                            color="primary"
                                            boundaryCount={1}
                                            defaultPage={page}
                                            onChange={handlePageChange}
                                        />
                                    </Stack>
                                </div>
                            </div>
                            : null}
                            <div className="row mt-3">
                                <div className="col-auto mx-auto">
                                    <p className="my-0">Showing {recommendation.shoes? shoes.length: null} options</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RecommendationReasoningModal openState={open} setOpenState={setOpen} recommendation={recommendation} maxScore={shoes[0]?.maxScore} />
            </div>
        </div>
        
    )
}
}