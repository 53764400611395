import Tooltip from "@mui/material/Tooltip"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

export default function GeneralTooltip ({title = null}) {
    return (
        <div className="col-auto px-0 mx-auto mx-md-0">
            <Tooltip arrow disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title={title}>
                <InfoOutlinedIcon/>
            </Tooltip>
        </div>

    )
}